import { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { backend_origin } from "../../configs/config";
import styles from "./toyList.module.css";

import tmp_img from "../../images/tmp_img.jpg";
import preparingImage from "../../images/preparingImage.png";

export default function NewToys() {
  // TODO : 신규장난감에 대한 기준을 정해야 한다.
  const [toyList, setToyList] = useState([]);

  const navigate = useNavigate();

  const getToyList = async () => {
    const response = await fetch(`${backend_origin}/api/toy/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log(json);
    setToyList(json.filter((toy) => toy.isNew === true));
  };

  useEffect(() => {
    getToyList();

    return () => {};
  }, []);

  return (
    <Layout>
      {/* <img className={styles.mainImage} src={tmp_img} alt="" /> */}
      <div className="wrapper">
        <h1>신규장난감</h1>
        <div className={styles.toysContainer} style={{marginTop: "60px"}}>
          {toyList.map((toy, index) => {
            return (
              <div
                className={styles.toy}
                key={`Toy-${index}`}
                onClick={() => {
                  navigate(`/toy/${toy.id}`);
                }}
              >
                <div className={styles.toy_name_wrapper}>
                  <h2>{toy.name}</h2>
                </div>
                <div className={styles.toy_img_wrapper}>
                <img
                  className={styles.toyImg}
                  src={
                    toy.image.length !== 0
                      ? `${backend_origin}${toy.image[0].image}`
                      : preparingImage
                  }
                />
                </div>
                <div className={styles.toy_detail_wrapper}>
                  <p className={styles.toy_category}>{toy.subCategory}</p>
                  <p className={toy.quantity !== 0 ? styles.toy_quantity : styles.toy_is_zero}>
                    {toy.quantity}개 대여가능
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
