import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { backend_origin } from "../../configs/config";

import Layout from "../../components/Layout/Layout";
import Veditor from "../../components/Editor/Veditor";

import styles from "../styles/boardUpdate.module.css";
import { getCookie } from "../../configs/cookie";
import { checkLogined, refreshToken } from "../../functions/account";

export default function BoardUpdate() {
  const navigate = useNavigate();
  const params = useParams();
  const articleId = params.id;

  const inputTitleRef = useRef(null);
  const [content, setContent] = useState("");

  const getArticleDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${articleId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 게시글입니다.");
      navigate("/board");
      return;
    }
    const json = await response.json();
    inputTitleRef.current.value = json.title;
    setContent(json.content);
  };

  const updateArticle = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${articleId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        title: inputTitleRef.current.value,
        content: content,
      }),
    });
    if (response.status === 200) {
      alert("게시글이 수정되었습니다.");
      navigate(`/board/${articleId}`);
      return;
    } else if (response.status === 400) {
      alert("제목과 내용을 모두 입력해주세요.");
    }
  };
  const deleteArticle = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${articleId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 204) {
      alert("삭제되었습니다.");
      navigate(`/board`);
      return;
    }
  };

  useEffect(() => {
    const result = checkLogined();
    if (result === false) {
      alert("로그인 후 이용하세요.");
      navigate("/login");
    }
    getArticleDetail();
  }, []);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>자유게시판 게시글 수정</h1>
        <div className={styles.editor_div}>
          <div>
            <input ref={inputTitleRef} placeholder="제목을 입력해주세요." />
          </div>
          <div style={{ paddingBottom: 40 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="board"
            />
          </div>
        </div>
        <div className={styles.btn_wrapper}>
          <button className={styles.saveBtn} onClick={updateArticle}>
            저장
          </button>
          <button className={styles.delBtn} onClick={deleteArticle}>
            삭제
          </button>
        </div>
      </div>
    </Layout>
  );
}
