import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";
import { useEffect, useState } from "react";
import styles from "./adminBoardDetail.module.css";

export default function AdminBoardDetail() {
  const params = useParams();
  const boardId = params.id;
  const navigate = useNavigate();

  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [author, setAuthor] = useState({});

  const getBoardDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${boardId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않습니다.");
      navigate("/adminPage/board");
      return;
    }
    const board = await response.json();
    console.log(board);
    board.content = board.content.replace(
      `img `,
      `img style="max-width:100%;"`
    );
    setTitle(board.title);
    setContent(board.content);
    setAuthor(board.author);
  };

  const deleteBoard = async () => {
    if (!window.confirm("정말 삭제합니까?")) {
      return;
    }
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh === undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${boardId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 204) {
      alert("삭제되었습니다.");
      navigate(`/adminPage/board`);
      return;
    }
  };

  useEffect(() => {
    getBoardDetail();
  }, []);

  return (
    <AdminLayout section={"board"}>
      {title && (
        <div className={styles.contents_wrapper}>
          <div className={styles.header}>자유게시판 게시글 관리</div>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>제목</td>
                <td className={styles.title}>
                  <div>{title}</div>
                </td>
              </tr>
              <tr>
                <td>내용</td>
                <td className={styles.content}>
                  <div
                    className={`view ql-editor`}
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </td>
              </tr>
              <tr>
                <td>작성자</td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>이름</td>
                        <td>{author.name}</td>
                      </tr>
                      <tr>
                        <td>아이디</td>
                        <td>{author.username}</td>
                      </tr>
                      <tr>
                        <td>이메일</td>
                        <td>{author.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.btn_wrapper}>
            <button className={styles.delBtn} onClick={deleteBoard}>
              삭제
            </button>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}
