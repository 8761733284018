import { backend_origin } from "../../../configs/config";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import AdminLayout from "../../../components/Layout/AdminLayout";
import styles from "./adminToy.module.css";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";

export default function AdminToy() {
  const [originToyList, setOriginToyList] = useState([]);
  const [toyList, setToyList] = useState([]);
  const [modToys, setModToys] = useState([]);

  const inputSearchRef = useRef(null);

  // pagination
  const [page, setPage] = useState(1);
  const limit = 10;
  const offset = (page - 1) * limit;

  const navigate = useNavigate();

  const getToyList = async () => {
    const response = await fetch(`${backend_origin}/api/toy/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setToyList(json);
    setOriginToyList(json);
  };

  const changeToy = (index, key, value) => {
    const tmpToyList = [...toyList];
    tmpToyList[index][key] = value;
    setToyList(tmpToyList);
  };
  const updateAllToys = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    for (const toy of toyList) {
      if (!modToys.includes(toy.id)) {
        continue;
      }
      const response = await fetch(`${backend_origin}/api/toy/${toy.id}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          quantity: toy.quantity,
        }),
      });
      if (response.status !== 200) {
        console.log(`adminToys - updateAllToys() : ${response.status}`);
      }
    }
    alert("저장되었습니다.");
    window.location.reload();
  };

  // pagination
  const page_data = (data) => {
    if (data) {
      let result = data.slice(offset, offset + limit);
      return result;
    }
  };

  useEffect(() => {
    getToyList();

    return () => {};
  }, []);

  return (
    <AdminLayout section={"toy"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>장난감 목록</div>
        <div className={styles.header_wrapper}>
          <div className={styles.search_wrapper}>
            <input ref={inputSearchRef} placeholder="검색어 입력" />
            <button
              className={styles.schBtn}
              onClick={() => {
                let keyword = inputSearchRef.current.value;
                const toyList_tmp = [...originToyList];
                const filterToyList = toyList_tmp.filter((toy) =>
                  toy.name.includes(keyword)
                );

                setToyList(filterToyList);
              }}
            >
              검색
            </button>
          </div>
          <span>
            <button
              className={styles.excelButton}
              onClick={() => {
                navigate("/adminPage/toyExcel");
              }}
            >
              엑셀 일괄 등록
            </button>
            <button className={styles.functionButton} onClick={updateAllToys}>
              일괄 수량 수정
            </button>
            <button
              className={styles.functionButton}
              onClick={() => {
                navigate("/adminPage/toyCreate");
              }}
            >
              생성
            </button>
          </span>
        </div>
        <table className={styles.toy_list}>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>대여가능 수량</th>
              <th>생성 날짜</th>
            </tr>
          </thead>
          <tbody>
            {page_data(toyList).map((toy, idx) => {
              return (
                <tr key={idx} className="Raw">
                  <td className={styles.id}>{toy.id}</td>
                  <td
                    className={styles.name}
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/adminpage/toy/${toy.id}`);
                    }}
                  >
                    {toy.name}
                  </td>
                  <td className={styles.quantity}>
                    <input
                      type="number"
                      value={toy.quantity}
                      placeholder="수량을 입력해주세요."
                      onChange={(event) => {
                        event.stopPropagation();
                        if (!modToys.includes(toy.id)) {
                          const tmpArr = [...modToys];
                          tmpArr.push(toy.id);
                          setModToys(tmpArr);
                        }
                        changeToy(offset + idx, "quantity", event.target.value);
                      }}
                    />
                    개
                  </td>
                  <td className={styles.date}>
                    {moment(toy.created_at).format("YYYY - MM - DD")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles.pagination_wrapper}>
          <button
            onClick={() => {
              setPage(page - 1);
            }}
            disabled={page === 1}
            className={styles.pagination_btn}
          >
            &lt;
          </button>
          {Array(Math.ceil(toyList.length / limit))
            .fill()
            .map((_, i) => {
              return (
                <button
                  key={i + 1}
                  onClick={() => setPage(i + 1)}
                  className={styles.num_btn}
                  disabled={page === i + 1}
                >
                  {i + 1}
                </button>
              );
            })}
          <button
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={
              page === Math.ceil(toyList.length / limit) || toyList.length === 0
            }
            className={styles.pagination_btn}
          >
            &gt;
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
