import { getCookie } from "../../configs/cookie";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { afterSocialLogin, logout } from "../../functions/account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/logo.png";
import styles from "./Header.module.css";
import Dropdown from "./Dropdown";
import DropdownMobile from "./DropdownForSidebar";
import DropdownForSidebar from "./DropdownForSidebar";

const itemList = {
  대여소소개: [
    { itemName: "인사말", itemLink: "/introduce" },
    { itemName: "장난감도서관", itemLink: "/jstoy_introduce" },
    { itemName: "오시는길", itemLink: "/direction" },
  ],
  이용안내: [
    { itemName: "이용안내", itemLink: "/info" },
    { itemName: "이용약관", itemLink: "/terms" },
  ],
  장난감검색: [
    { itemName: "장난감검색", itemLink: "/search" },
    { itemName: "신규장난감", itemLink: "/new" },
  ],
  열린마당: [
    { itemName: "공지사항", itemLink: "/notice" },
    { itemName: "자유게시판", itemLink: "/board" },
    { itemName: "이용후기", itemLink: "/review" },
  ],
};

function Header() {
  const navigate = useNavigate();
  const refresh = getCookie("refresh");

  const [toggleRight, setToggleRight] = useState(false);
  const [toggleLeft, setToggleLeft] = useState(false);

  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    afterSocialLogin();
  }, []);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerTopBar}>
        <div className={styles.headerTopBarInner}>
          <ul className={styles.headerTopBarList}>
            {refresh ? (
              <>
                <li>
                  <a onClick={logout}>로그아웃</a>
                </li>
                <li>
                  <a href="/mypage">마이페이지</a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="/login">로그인</a>
                </li>
                <li>
                  <a href="/signup">회원가입</a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <header className={styles.headerBar}>
        <div className={styles.headerBarInner}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="logo" />
          </Link>
          <ul className={styles.headerMenuWrapper}>
            <Dropdown
              title={"대여소소개"}
              contents={{
                인사말: "/introduce",
                장난감도서관: "/jstoy_introduce",
                오시는길: "/direction",
              }}
            />
            <Dropdown
              title={"이용안내"}
              contents={{
                이용안내: "/info",
                이용약관: "/terms",
              }}
            />
            <Dropdown
              title={"장난감검색"}
              contents={{
                장난감검색: "/search",
                신규장난감: "/new",
              }}
            />
            <Dropdown
              title={"열린마당"}
              contents={{
                공지사항: "/notice",
                자유게시판: "/board",
                이용후기: "/review",
              }}
            />
          </ul>
        </div>
      </header>
      {/* 모바일 헤더 start*/}
      <div className={styles.mobileHeaderBar}>
        <div
          className={styles.leftToggle}
          onClick={() => {
            setToggleLeft(!toggleLeft);
          }}
        >
          <FontAwesomeIcon
            icon={faBars}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div
          style={{
            visibility: toggleLeft ? "visible" : "hidden",
            left: toggleLeft ? "0px" : "-220px",
          }}
          className={styles.leftSide}
        >
          <DropdownForSidebar
            title="대여소소개"
            itemList={itemList}
            selected={selectedTab === "대여소소개"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <DropdownForSidebar
            title="이용안내"
            itemList={itemList}
            selected={selectedTab === "이용안내"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <DropdownForSidebar
            title="장난감검색"
            itemList={itemList}
            selected={selectedTab === "장난감검색"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <DropdownForSidebar
            title="열린마당"
            itemList={itemList}
            selected={selectedTab === "열린마당"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <Link to="/" className={styles.logo}>
          <img className={styles.logo_img} src={logo} alt="logo" />
        </Link>
        <div
          className={styles.rightToggle}
          onClick={() => {
            setToggleRight(!toggleRight);
          }}
        >
          <FontAwesomeIcon
            icon={faUser}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div
          style={{
            visibility: toggleRight ? "visible" : "hidden",
            right: toggleRight ? "0px" : "-220px",
          }}
          className={styles.rightSide}
        >
          {refresh ? (
            <>
              <div>
                <a onClick={logout}>로그아웃</a>
              </div>
              <div>
                <a href="/mypage">마이페이지</a>
              </div>
            </>
          ) : (
            <>
              <div>
                <a href="/login">로그인</a>
              </div>
              <div>
                <a href="/signup">회원가입</a>
              </div>
            </>
          )}
        </div>
        <button
          className={styles.closeSide}
          style={{
            visibility: toggleRight || toggleLeft ? "visible" : "hidden",
            opacity: toggleRight || toggleLeft ? "1" : "0",
          }}
          onClick={() => {
            setToggleRight(false);
            setToggleLeft(false);
          }}
        ></button>
      </div>
      {/* 모바일 헤더 end*/}
    </div>
  );
}

export default Header;
