import { backend_origin } from "../../../configs/config";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import AdminLayout from "../../../components/Layout/AdminLayout";
import styles from "./adminReview.module.css";
import { getStar } from "../../../functions/getStar";

export default function AdminReview() {
  const [originReviewList, setOriginReviewList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const inputSearchRef = useRef(null);

  const navigate = useNavigate();

  const getReviewList = async () => {
    const response = await fetch(`${backend_origin}/api/review/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setReviewList(json);
    setOriginReviewList(json);
  };

  useEffect(() => {
    getReviewList();

    return () => {};
  }, []);

  return (
    <AdminLayout section={"review"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>후기 목록</div>
        <div className={styles.header_wrapper}>
          <div className={styles.search_wrapper}>
            <input ref={inputSearchRef} placeholder="검색어 입력" />
            <button
              className={styles.schBtn}
              onClick={() => {
                let keyword = inputSearchRef.current.value;
                const reviewList_tmp = [...originReviewList];
                const filteredReviewList = reviewList_tmp.filter((review) =>
                  review.reviewTitle.includes(keyword)
                );

                setReviewList(filteredReviewList);
              }}
            >
              검색
            </button>
          </div>
        </div>
        <table className={styles.list}>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>별점</th>
              <th>만든 날짜</th>
            </tr>
          </thead>
          <tbody>
            {reviewList.map((review, idx) => {
              return (
                <tr
                  key={idx}
                  className="Raw"
                  onClick={() => {
                    navigate(`/adminPage/review/${review.id}`);
                  }}
                >
                  <td className={styles.id}>{review.id}</td>
                  <td className={styles.title}>{review.reviewTitle}</td>
                  <td className={styles.rating}>{getStar(review.rating)}</td>
                  <td className={styles.date}>
                    {moment(review.created_at).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
