import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";

export const getStar = (rating) => {
  let extraRating = 5 - rating;
  let result = [];

  for (let i = 0; i < rating; i++) {
    result.push(<FontAwesomeIcon icon={faStarSolid} />);
  }
  for (let i = 0; i < extraRating; i++) {
    result.push(<FontAwesomeIcon icon={faStarRegular} />);
  }

  return result;
};
