import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { backend_origin } from "../../configs/config";

import Layout from "../../components/Layout/Layout";
import Veditor from "../../components/Editor/Veditor";

import styles from "../styles/reviewWrite.module.css";
import { getCookie } from "../../configs/cookie";
import { checkLogined, refreshToken } from "../../functions/account";
import { getStar } from "../../functions/getStar";

export default function ReviewWrite() {
  const navigate = useNavigate();

  const inputTitleRef = useRef(null);
  const [selectedReviewRating, setSelectedReviewRating] = useState(5);
  const [content, setContent] = useState("");

  const postArticle = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const response = await fetch(`${backend_origin}/api/review/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        reviewTitle: inputTitleRef.current.value,
        reviewContent: content,
        rating: selectedReviewRating,
      }),
    });
    if (response.status === 200) {
      const json = await response.json();
      alert("후기가 등록되었습니다.");
      navigate(`/review/${json.id}`);
    } else if (response.status === 400) {
      alert("제목과 내용, 평점을 모두 입력해주세요.");
    }
  };

  const handleRatingChange = (e) => {
    setSelectedReviewRating(Number(e.target.value));
  };

  useEffect(() => {
    const result = checkLogined();
    if (result === false) {
      alert("로그인 후 이용하세요.");
      navigate("/login");
    }
  }, []);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>후기 작성</h1>
        <div className={styles.editor_div}>
          <div>
            <input ref={inputTitleRef} placeholder="제목을 입력해주세요." />
          </div>
          <div className={styles.starList}>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={1}
                checked={selectedReviewRating === 1}
                onChange={handleRatingChange}
              />
              {getStar(1)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={2}
                checked={selectedReviewRating === 2}
                onChange={handleRatingChange}
              />
              {getStar(2)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={3}
                checked={selectedReviewRating === 3}
                onChange={handleRatingChange}
              />
              {getStar(3)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={4}
                checked={selectedReviewRating === 4}
                onChange={handleRatingChange}
              />
              {getStar(4)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={5}
                checked={selectedReviewRating === 5}
                onChange={handleRatingChange}
              />
              {getStar(5)}
            </label>
          </div>
          <div style={{ paddingBottom: 40 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="review"
            />
          </div>
        </div>
      </div>
      <div className={styles.submit}>
        <div>
          <button onClick={postArticle}>제출</button>
        </div>
      </div>
    </Layout>
  );
}
