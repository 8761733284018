import { getCookie } from "../configs/cookie";
import { backend_origin } from "../configs/config";
import { logout, refreshToken } from "../functions/account";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import Layout from "../components/Layout/Layout";
import tmp_img2 from "../images/tmp_img2.jpg";

import styles from "./styles/mypage.module.css";
import MainBanner from "../components/MainBanner/MainBanner";

function MyPage() {
  const navigate = useNavigate();

  const [profileInfo, setProfileInfo] = useState(null);

  const userNameInput = useRef(null);
  const userNameInputWarn = useRef(null);
  const userEmailInput = useRef(null);
  const userEmailInputWarn = useRef(null);

  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  let tab = params.get("tab");
  if (!tab) {
    tab = "profileUpdate";
  }

  const getUserProfile = async () => {
    while (true) {
      const access = getCookie("access");
      const refresh = getCookie("refresh");
      if (access || refresh) {
        const response = await fetch(`${backend_origin}/api/user/profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        });
        if (response.status === 200) {
          // 사용자 프로필을 가져오는데 성공
          const json = await response.json();
          setProfileInfo(json);
          break;
        } else if (response.status === 401) {
          // 토큰이 만료되어 갱신시도
          const result = await refreshToken();
          if (!result) {
            alert("로그인 후 이용해주세요.");
            navigate("/login");
            return;
          }
        } else {
          alert("로그인 후 이용해주세요.");
          navigate("/login");
          return;
        }
      } else {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return false;
      }
    }
  };

  // 사용자 정보수정 element
  const UpdateUserInfo = () => {
    const updateUserData = async () => {
      let access = getCookie("access");
      const refresh = getCookie("refresh");
      if (access === undefined && refresh !== undefined) {
        // 토큰이 만료되어 갱신시도
        const result = await refreshToken();
        if (result !== false) {
          access = result;
        }
      }
      const response = await fetch(`${backend_origin}/api/user/profile/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          name: userNameInput.current.value,
          email: userEmailInput.current.value,
        }),
      });
      if (response.status === 200) {
        alert("수정되었습니다.");
        window.location.reload();
      } else {
        const json = await response.json();
        if ("name" in json) {
          userNameInput.current.style.borderBottom = "1px solid red";
          userNameInputWarn.current.style.visibility = "visible";
          userNameInputWarn.current.textContent = json.name[0];
        } else {
          userNameInput.current.style.borderBottom = "unset";
          userNameInputWarn.current.style.visibility = "hidden";
        }
        if ("email" in json) {
          userEmailInput.current.style.borderBottom = "1px solid red";
          userEmailInputWarn.current.style.visibility = "visible";
          userEmailInputWarn.current.textContent = json.email[0];
        } else {
          userEmailInput.current.style.borderBottom = "unset";
          userEmailInputWarn.current.style.visibility = "hidden";
        }
      }
    };

    return (
      <div className={styles.contentWrapper}>
        {profileInfo ? (
          <>
            <table className={styles.profileTable}>
              <tr>
                <td>이름</td>
                <td>
                  <input ref={userNameInput} defaultValue={profileInfo.name} />
                  <span
                    ref={userNameInputWarn}
                    style={{ visibility: "hidden" }}
                  ></span>
                </td>
              </tr>
              <tr>
                <td>이메일</td>
                <td>
                  <input
                    ref={userEmailInput}
                    defaultValue={profileInfo.email}
                  />
                  <span
                    ref={userEmailInputWarn}
                    style={{ visibility: "hidden" }}
                  ></span>
                </td>
              </tr>
            </table>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.updateButton}
                onClick={() => {
                  updateUserData();
                }}
              >
                수정
              </button>
            </div>
          </>
        ) : null}
      </div>
    );
  };
  // 비밀번호 변경 element
  const ChangePassword = () => {
    const oldPasswordInput = useRef(null);
    const newPasswordInput = useRef(null);
    const newPasswordConfirmInput = useRef(null);

    const updateUserPassword = async () => {
      if (
        newPasswordInput.current.value !== newPasswordConfirmInput.current.value
      ) {
        alert("새로운 비밀번호와 확인 비밀번호가 다릅니다.");
        return;
      }
      let access = getCookie("access");
      const refresh = getCookie("refresh");
      if (access === undefined && refresh !== undefined) {
        // 토큰이 만료되어 갱신시도
        const result = await refreshToken();
        if (result !== false) {
          access = result;
        }
      }
      const response = await fetch(
        `${backend_origin}/api/user/changePassword/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify({
            oldPassword: oldPasswordInput.current.value,
            newPassword: newPasswordInput.current.value,
          }),
        }
      );
      if (response.status === 200) {
        alert("비밀번호가 변경되었습니다.");
        window.location.reload();
      } else {
        const json = await response.json();
        if ("password" in json) {
          alert(json.password[0]);
        }
      }
    };
    return (
      <div className={styles.contentWrapper}>
        <table className={styles.changePasswordTable}>
          <tr>
            <td>
              <span>기존 비밀번호</span>
            </td>
            <td>
              <input ref={oldPasswordInput} type="password" />
            </td>
          </tr>
          <tr>
            <td>
              <span>새로운 비밀번호</span>
            </td>
            <td>
              <input ref={newPasswordInput} type="password" />
            </td>
          </tr>
          <tr>
            <td>
              <span>새로운 비밀번호 확인</span>
            </td>
            <td>
              <input ref={newPasswordConfirmInput} type="password" />
            </td>
          </tr>
        </table>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.updateButton}
            onClick={() => {
              updateUserPassword();
            }}
          >
            수정
          </button>
        </div>
      </div>
    );
  };
  // 회원탈퇴 element
  const Withdraw = () => {
    const passwordInput = useRef(null);
    const checkInput = useRef(null);
    const withdrawUser = async () => {
      if (!checkInput.current.checked) {
        alert("확인 체크박스를 체크해주세요.");
        return;
      }
      if (!window.confirm("정말 회원탈퇴합니까?")) {
        return;
      }
      let access = getCookie("access");
      const refresh = getCookie("refresh");
      if (access === undefined && refresh !== undefined) {
        // 토큰이 만료되어 갱신시도
        const result = await refreshToken();
        if (result !== false) {
          access = result;
        }
      }
      const response = await fetch(`${backend_origin}/api/user/withdraw/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          password: passwordInput.current ? passwordInput.current.value : null,
          isSocial:
            !profileInfo.isSignUp && profileInfo.isSocial ? true : false,
        }),
      });
      if (response.status === 200) {
        alert("탈퇴되었습니다.");
        logout();
      } else {
        const json = await response.json();
        if ("password" in json) {
          alert(json.password[0]);
        }
      }
    };
    return (
      <div className={styles.contentWrapper}>
        {profileInfo ? (
          <>
            <table className={styles.withdrawTable}>
              {profileInfo.isSignUp ? (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div>비밀번호를 통해 가입된 이력이 있는 계정입니다.</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>비밀번호</span>
                    </td>
                    <td>
                      <input ref={passwordInput} type="password" />
                    </td>
                  </tr>
                </>
              ) : null}
              <tr>
                <td colSpan={2}>
                  <span>모든 정보가 삭제됩니다.</span>
                  <input ref={checkInput} type="checkbox" />
                </td>
              </tr>
            </table>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.withdrawButton}
                onClick={() => {
                  withdrawUser();
                }}
              >
                회원탈퇴
              </button>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    getUserProfile();
    return () => {};
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title={"Mypage"} />
      <div className="wrapper">
        {profileInfo ? (
          <div className={styles.innerWrapper}>
            {/* 모바일용 tab */}
            <div className={styles.tabWrapperMobile}>
              <li
                className={tab === "profileUpdate" ? styles.selectedTab : null}
              >
                <a href="/mypage?tab=profileUpdate">회원정보수정</a>
              </li>
              <li
                className={tab === "changePassword" ? styles.selectedTab : null}
              >
                <a href="/mypage?tab=changePassword">비밀번호수정</a>
              </li>
              <li className={tab === "withdraw" ? styles.selectedTab : null}>
                <a href="/mypage?tab=withdraw">회원탈퇴</a>
              </li>
              {profileInfo && profileInfo.isManager ? (
                <li>
                  <a href="/adminpage">관리자페이지</a>
                </li>
              ) : null}
            </div>
            <div className={styles.tabWrapper}>
              <ul>
                <li
                  className={
                    tab === "profileUpdate" ? styles.selectedTab : null
                  }
                >
                  <a href="/mypage?tab=profileUpdate">회원정보수정</a>
                </li>
                {profileInfo.isSignUp ? (
                  <li
                    className={
                      tab === "changePassword" ? styles.selectedTab : null
                    }
                  >
                    <a href="/mypage?tab=changePassword">비밀번호수정</a>
                  </li>
                ) : null}
                <li className={tab === "withdraw" ? styles.selectedTab : null}>
                  <a href="/mypage?tab=withdraw">회원탈퇴</a>
                </li>
                {profileInfo && profileInfo.isManager ? (
                  <li>
                    <a href="/adminpage">관리자페이지</a>
                  </li>
                ) : null}
              </ul>
            </div>
            {tab === "profileUpdate" ? <UpdateUserInfo /> : null}
            {tab === "changePassword" && profileInfo.isSignUp ? (
              <ChangePassword />
            ) : null}
            {tab === "withdraw" ? <Withdraw /> : null}
          </div>
        ) : null}
      </div>
    </Layout>
  );
}

export default MyPage;
