import moment from "moment";

import { getCookie } from "../../configs/cookie";
import { backend_origin } from "../../configs/config";
import { checkLogined, refreshToken } from "../../functions/account";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPencil } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout/Layout";
import tmp_img2 from "../../images/tmp_img2.jpg";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/reviewDetail.module.css";
import { getStar } from "../../functions/getStar";

export default function ReviewDetail() {
  const params = useParams();
  const reviewId = params.id;
  const navigate = useNavigate();

  const [review, setReview] = useState(null);

  const getArticleDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/review/${reviewId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 게시글입니다.");
      navigate("/review");
      return;
    }
    const json = await response.json();
    json.reviewContent = json.reviewContent.replace(
      `img `,
      `img style="max-width:100%;"`
    );
    setReview(json);
  };

  useEffect(() => {
    getArticleDetail();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title={"이용후기"} />
      <div className={styles.wrapper}>
        <div className={styles.content_wrapper}>
          {review ? (
            <>
              <div className={styles.content}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    <span>{review.reviewTitle}</span>
                    <span>{getStar(review.rating)}</span>
                  </div>
                  <div>
                    <span
                      className={styles.backToList}
                      onClick={() => navigate(`/review`)}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                    {review.isAuthor ? (
                      <span
                        className={styles.modify}
                        onClick={() => navigate(`/review/${reviewId}/update`)}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </span>
                    ) : null}
                    <span className={styles.date}>
                      {moment(review.created_at).format("YYYY-MM-DD")}
                    </span>
                  </div>
                </div>
                <div
                  className="view ql-editor"
                  dangerouslySetInnerHTML={{ __html: review.reviewContent }}
                ></div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.backToListButton}
                  onClick={() => {
                    navigate(`/review`);
                  }}
                >
                  목록으로
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
