import moment from "moment";

import { backend_origin } from "../configs/config";
import { setCookie, getCookie, removeCookie } from "../configs/cookie";

export const afterSocialLogin = async () => {
  const isAfterSocialLogin = getCookie("socialLoginFinished");
  if (isAfterSocialLogin) {
    removeCookie("socialLoginFinished");

    const access = getCookie("access");
    const refresh = getCookie("refresh");

    const twoWeeks = moment().add("2", "weeks").toDate();
    const fiveMinutes = moment().add("5", "m").toDate();
    setCookie("refresh", refresh, {
      path: "/",
      secure: true,
      expires: twoWeeks,
    });
    setCookie("access", access, {
      path: "/",
      secure: true,
      expires: fiveMinutes,
    });
    setCookie("remember", true, {
      path: "/",
      secure: true,
      expires: twoWeeks,
    });
    window.location.replace("/");

    return;
  }
};

export const refreshToken = async () => {
  const refresh = getCookie("refresh");
  const isRemember = getCookie("remember");
  const response = await fetch(`${backend_origin}/api/token/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refresh,
    }),
  });
  if (response.status === 200) {
    const json = await response.json();

    const twoWeeks = moment().add("2", "weeks").toDate();
    const fiveMinutes = moment().add("5", "m").toDate();
    setCookie("refresh", json.refresh, {
      path: "/",
      secure: true,
      expires: isRemember ? twoWeeks : 0,
    });
    setCookie("access", json.access, {
      path: "/",
      secure: true,
      expires: fiveMinutes,
    });
    setCookie("remember", isRemember ? true : false, {
      path: "/",
      secure: true,
      expires: isRemember ? twoWeeks : 0,
    });

    return json.access;
  } else {
    return false;
  }
};

export const login = async (event, isRemember, id, password) => {
  event.preventDefault();
  const response = await fetch(`${backend_origin}/api/token/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: id,
      password: password,
    }),
  });
  if (response.status === 200) {
    const json = await response.json();
    const twoWeeks = moment().add("2", "weeks").toDate();
    const fiveMinutes = moment().add("5", "m").toDate();
    setCookie("refresh", json.refresh, {
      path: "/",
      // secure: true,
      expires: isRemember ? twoWeeks : 0,
    });
    setCookie("access", json.access, {
      path: "/",
      // secure: true,
      expires: fiveMinutes,
    });
    setCookie("remember", isRemember ? true : false, {
      path: "/",
      // secure: true,
      expires: isRemember ? twoWeeks : 0,
    });
    window.location.replace("/");
  } else if (response.status === 401) {
    alert("비활성화된 계정입니다.");
  } else {
    console.error("Login function : ", response.status);
  }
};

export const checkLogined = () => {
  const refresh = getCookie("refresh");
  if (refresh) return true;
  else return false;
};

export const checkIsManager = async () => {
  const refresh = getCookie("refresh");
  if (refresh) {
    let access = getCookie("access");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/user/profile/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 200) {
      // 사용자 프로필을 가져오는데 성공
      const json = await response.json();
      return json.isManager;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const logout = async () => {
  const access = getCookie("access");
  if (access) {
    const response = await fetch(`${backend_origin}/api/user/logout/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
  }
  removeCookie("access");
  removeCookie("refresh");
  removeCookie("remember");
  window.location.replace("/");
};
