import styles from "./Dropdown.module.css";
import { Link } from "react-router-dom";

import { useState } from "react";

const Dropdown = ({ title, contents }) => {
  const [show, setShow] = useState(false);
  const contents_keys = Object.keys(contents);
  const listItems = contents_keys.map((contents_key, idx) => (
    <li key={idx}><Link to={contents[contents_key]}>{contents_key}</Link></li>
  ));

  return (
    <li
      onMouseLeave={() => setShow(false)}
      onMouseOver={() => setShow(true)}
      className={styles.detail_wrapper}
    >
      <span>{title}</span>
      <ul className={styles.detail_navmenus}>{show && listItems}</ul>
    </li>
  );
};

export default Dropdown;
