import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { checkIsManager } from "../../functions/account";

import AdminHeader from "../Header/AdminHeader";
import AdminSidebar from "../SideBar/AdminSidebar";

import "./AdminLayout.css";

export default function AdminLayout({ children, section }) {
  const navigate = useNavigate();
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    const checkManagerLogined = async () => {
      const result = await checkIsManager();
      if (result === false) {
        alert("비정상적인 접근입니다.");
        navigate("/");
      }
      setIsManager(result);
    };
    checkManagerLogined();
  }, []);

  return (
    <>
      {isManager ? (
        <>
          <div className="AdminLayoutContainer">
            <AdminHeader />
            <div className="AdminPanelBodyWrapper">
              <AdminSidebar section={section} />
              <div
                className="AdminPanelContentWrapper"
                style={{ width: "100%" }}
              >
                {children}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
