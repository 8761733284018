import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { backend_origin } from "../../configs/config";

import Layout from "../../components/Layout/Layout";
import Veditor from "../../components/Editor/Veditor";

import styles from "../styles/boardWrite.module.css";
import { getCookie } from "../../configs/cookie";
import { checkLogined, refreshToken } from "../../functions/account";

export default function BoardWrite() {
  const navigate = useNavigate();

  const inputTitleRef = useRef(null);
  const [content, setContent] = useState("");

  const postArticle = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const response = await fetch(`${backend_origin}/api/board/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        title: inputTitleRef.current.value,
        content: content,
      }),
    });
    if (response.status === 200) {
      const json = await response.json();
      alert("게시글이 등록되었습니다.");
      navigate(`/board/${json.id}`);
    } else if (response.status === 400) {
      alert("제목과 내용을 모두 입력해주세요.");
    }
  };

  useEffect(() => {
    const result = checkLogined();
    if (result === false) {
      alert("로그인 후 이용하세요.");
      navigate("/login");
    }
  }, []);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>자유게시판 게시글 작성</h1>
        <div className={styles.editor_div}>
          <div>
            <input ref={inputTitleRef} placeholder="제목을 입력해주세요." />
          </div>
          <div style={{ paddingBottom: 40 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="board"
            />
          </div>
        </div>
      </div>
      <div className={styles.submit}>
        <button onClick={postArticle}>제출</button>
      </div>
    </Layout>
  );
}
