import moment from "moment";

import { backend_origin } from "../../configs/config";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout/Layout";
import tmp_img2 from "../../images/tmp_img2.jpg";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/noticeDetail.module.css";

export default function NoticeDetail() {
  const params = useParams();
  const noticeId = params.id;
  const navigate = useNavigate();

  const [notice, setNotice] = useState(null);

  const getArticleDetail = async () => {
    const response = await fetch(`${backend_origin}/api/notice/${noticeId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 게시글입니다.");
      navigate("/notice");
      return;
    }
    const json = await response.json();
    json.content = json.content.replace(`img `, `img style="max-width:100%;"`);
    setNotice(json);
  };

  useEffect(() => {
    getArticleDetail();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title={"공지사항"} />
      <div className={styles.wrapper}>
        <div className={styles.content_wrapper}>
          {notice ? (
            <>
              <div className={styles.content}>
                <div className={styles.header}>
                  <span className={styles.title}>{notice.title}</span>
                  <div>
                    <span
                      className={styles.backToList}
                      onClick={() => navigate(`/notice`)}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                    <span className={styles.date}>
                      {moment(notice.created_at).format("YYYY-MM-DD")}
                    </span>
                  </div>
                </div>
                <div
                  className="view ql-editor"
                  dangerouslySetInnerHTML={{ __html: notice.content }}
                ></div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.backToListButton}
                  onClick={() => {
                    navigate(`/notice`);
                  }}
                >
                  목록으로
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
