import { backend_origin } from "../../configs/config";

import moment from "moment";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/Layout/Layout";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/review.module.css";

import tmp_img2 from "../../images/tmp_img2.jpg";
import { getStar } from "../../functions/getStar";

export default function Review() {
  const [reviewList, setReviewList] = useState([]);

  const navigate = useNavigate();

  const getReviewList = async () => {
    const response = await fetch(`${backend_origin}/api/review/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      console.log(json);
      setReviewList(json);
    }
  };

  useEffect(() => {
    getReviewList();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title="이용후기" />
      <div className="wrapper">
        <div className={styles.tableFunctionDiv}>
          <button
            className={styles.writeButton}
            onClick={() => {
              navigate("write");
            }}
          >
            글쓰기
          </button>
        </div>
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>별점</th>
              <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            {reviewList.map((review, index) => (
              <tr
                key={`review_${index}`}
                onClick={() => {
                  navigate(`/review/${review.id}`);
                }}
              >
                <td>{review.id}</td>
                <td>{review.reviewTitle}</td>
                <td className={styles.stars}>{getStar(review.rating)}</td>
                <td>{moment(review.created_at).format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
