import { backend_origin } from "../configs/config";
import { setCookie } from "../configs/cookie";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";

import styles from "./styles/signUp.module.css";

function SignUp() {
  const inputUsername = useRef(null);
  const inputUsernameWarn = useRef(null);
  const inputPassword = useRef(null);
  const inputPasswordWarn = useRef(null);
  const inputPasswordConfirm = useRef(null);
  const inputName = useRef(null);
  const inputEmail = useRef(null);
  const inputEmailWarn = useRef(null);

  const inputServiceCheck = useRef(null);
  const inputPrivacyCheck = useRef(null);

  const navigate = useNavigate();

  const submit = async (event) => {
    event.preventDefault();

    if (
      !inputServiceCheck.current.checked ||
      !inputPrivacyCheck.current.checked
    ) {
      alert("약관에 동의하셔야 회원가입이 가능합니다.");
      return;
    }

    let isBlank = false;
    if (inputUsername.current.value === "") {
      inputUsername.current.style.border = "1px solid red";
      isBlank = true;
    } else inputUsername.current.style.border = "unset";
    if (inputPassword.current.value === "") {
      inputPassword.current.style.border = "1px solid red";
      isBlank = true;
    } else inputPassword.current.style.border = "unset";
    if (inputPasswordConfirm.current.value === "") {
      inputPasswordConfirm.current.style.border = "1px solid red";
      isBlank = true;
    } else inputPasswordConfirm.current.style.border = "unset";
    if (inputName.current.value === "") {
      inputName.current.style.border = "1px solid red";
      isBlank = true;
    } else inputName.current.style.border = "unset";
    if (inputEmail.current.value === "") {
      inputEmail.current.style.border = "1px solid red";
      isBlank = true;
    } else inputEmail.current.style.border = "unset";

    if (isBlank) {
      alert("모든 정보를 입력해주세요");
      return;
    }

    if (inputPassword.current.value !== inputPasswordConfirm.current.value) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    const response = await fetch(`${backend_origin}/api/user/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: inputUsername.current.value,
        password: inputPassword.current.value,
        name: inputName.current.value,
        email: inputEmail.current.value,
      }),
    });
    if (response.status === 200) {
      const json = await response.json();

      setCookie("refresh", json.refresh, {
        path: "/",
        secure: true,
        expires: 0,
      });
      setCookie("access", json.access, {
        path: "/",
        secure: true,
        expires: 0,
      });
      setCookie("remember", false, {
        path: "/",
        secure: true,
        expires: 0,
      });

      alert("가입이 완료되었습니다.");
      navigate("/");
    } else {
      const json = await response.json();
      if ("username" in json) {
        inputUsername.current.style.borderBottom = "1px solid red";
        inputUsernameWarn.current.style.visibility = "visible";
        if (json.username[0] === "해당 사용자 이름은 이미 존재합니다.")
          json.username[0] = "해당 아이디는 이미 존재합니다.";
        inputUsernameWarn.current.textContent = json.username[0];
      } else {
        inputUsername.current.style.borderBottom = "unset";
        inputUsernameWarn.current.style.visibility = "hidden";
      }
      if ("password" in json) {
        inputPassword.current.style.borderBottom = "1px solid red";
        inputPasswordWarn.current.style.visibility = "visible";
        inputPasswordWarn.current.textContent = json.password[0];
      } else {
        inputPassword.current.style.borderBottom = "unset";
        inputPasswordWarn.current.style.visibility = "hidden";
      }
      if ("email" in json) {
        inputEmail.current.style.borderBottom = "1px solid red";
        inputEmailWarn.current.style.visibility = "visible";
        inputEmailWarn.current.textContent = json.email[0];
      } else {
        inputEmail.current.style.borderBottom = "unset";
        inputEmailWarn.current.style.visibility = "hidden";
      }
    }
  };

  return (
    <Layout>
      <div
        className="wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form onSubmit={submit} style={{ width: "100%" }}>
          <table className={styles.signUpTable}>
            <tr>
              <td>아이디</td>
              <td>
                <input
                  type="text"
                  ref={inputUsername}
                  placeholder="아이디를 입력하세요"
                />
                <br />
                <span
                  ref={inputUsernameWarn}
                  style={{ visibility: "hidden" }}
                ></span>
              </td>
            </tr>
            <tr>
              <td>비밀번호</td>
              <td>
                <input
                  type="password"
                  ref={inputPassword}
                  placeholder="비밀번호를 입력하세요"
                />
                <br />
                <span
                  ref={inputPasswordWarn}
                  style={{ visibility: "hidden" }}
                ></span>
              </td>
            </tr>
            <tr>
              <td>비밀번호 확인</td>
              <td>
                <input
                  type="password"
                  ref={inputPasswordConfirm}
                  placeholder="확인 비밀번호를 입력하세요"
                />
              </td>
            </tr>
            <tr>
              <td>이름</td>
              <td>
                <input
                  type="text"
                  ref={inputName}
                  placeholder="이름을 입력하세요"
                />
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>
                <input
                  type="text"
                  ref={inputEmail}
                  placeholder="이메일을 입력하세요"
                />
                <br />
                <span
                  ref={inputEmailWarn}
                  style={{ visibility: "hidden" }}
                ></span>
              </td>
            </tr>
          </table>
          <div className={styles.policyWrapper}>
            <h2>약관동의</h2>
            <br />
            {/* 이용약관 */}
            <h3>이용약관[필수]</h3>
            <div className={styles.policyService}>
              <h3>제1조(목적)</h3>
              <p>
                이 약관은 정선 아리 장난감 도서관 회사가 운영하는
                aritoy.co.kr(이하 “웹 사이트”이라 한다)에서 제공하는 인터넷 관련
                서비스(이하 “서비스”라 한다)를 이용함에 있어 웹 사이트와
                이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
              </p>

              <h3>제2조(정의)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”란 아리 장난감 도서관 회사가 재화 또는 용역(이하
                    “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등
                    정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한
                    가상의 영업장을 말하며, 아울러 사이버웹 사이트을 운영하는
                    사업자의 의미로도 사용합니다.
                  </li>
                  <li>
                    “이용자”란 “웹 사이트”에 접속하여 이 약관에 따라 “웹
                    사이트”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
                  </li>
                  <li>
                    ‘회원’이라 함은 “웹 사이트”에 회원가입을 한 자로서,
                    계속적으로 “웹 사이트”가 제공하는 서비스를 이용할 수 있는
                    자를 말합니다.
                  </li>
                  <li>
                    ‘비회원’이라 함은 회원 가입하지 않고 “웹 사이트”가 제공하는
                    서비스를 이용하는 자를 말합니다. “웹 사이트”에서 제공하는
                    기능 중 접근이 불가한 기능이 있을 수 있습니다.
                  </li>
                </ol>
              </p>

              <h3>제3조 (약관 등의 명시와 설명 및 개정)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이 약관의 내용과 상호 및 대표자 성명,
                    전자우편주소, 사업자등록번호, 통신판매업 신고번호, 등을
                    이용자가 쉽게 알 수 있도록 웹 사이트의 초기
                    서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가
                    연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                  </li>
                  <li>
                    “웹 사이트”는 이용자가 약관에 동의하기에 앞서 약관에
                    정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은
                    중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는
                    팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
                  </li>
                  <li>
                    “웹 사이트”는 「전자상거래 등에서의 소비자보호에 관한
                    법률」, 「약관의 규제에 관한 법률」, 「전자문서 및
                    전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
                    「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                    「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을
                    위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                  </li>
                  <li>
                    “웹 사이트”가 약관을 개정할 경우에는 적용일자 및 개정사유를
                    명시하여 현행약관과 함께 웹 사이트의 초기화면에 그 적용일자
                    7일 이전부터 적용일자 전일까지 공지사항 게시판에 공지합니다.
                  </li>
                  <li>
                    “웹 사이트”가 약관을 개정할 경우에는 그 개정약관은 그
                    적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
                    체결된 계약에 대해서는 개정 전의 약관조항이 그대로
                    적용됩니다.
                  </li>
                </ol>
              </p>

              <h3>제4조(서비스의 제공 및 변경)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 다음과 같은 업무를 수행합니다.
                    <ol>
                      <li>장난감 대여, 안내 및 관리</li>
                      <li>자유게시판 운영</li>
                    </ol>
                  </li>
                  <li>
                    “웹 사이트”가 제공하기로 이용자와 계약을 체결한 서비스의
                    내용을 이용자의 요청 등의 사유로 변경할 경우에는 그 사유로
                    인한 추가 내용을 이용자에게 통지 가능한 방식으로 즉시
                    통지합니다.
                  </li>
                </ol>
              </p>

              <h3>제5조(서비스의 중단)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 컴퓨터 등 정보통신설비의 보수점검․교체 및
                    고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
                    제공을 일시적으로 중단할 수 있습니다.
                  </li>
                  <li>
                    “웹 사이트”는 제1항의 사유로 서비스의 제공이 일시적으로
                    중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여
                    배상합니다. 단, “웹 사이트”가 고의 또는 과실이 없음을
                    입증하는 경우에는 그러하지 아니합니다.
                  </li>
                </ol>
              </p>

              <h3>제6조(회원가입)</h3>
              <p>
                <ol>
                  <li>
                    이용자는 “웹 사이트”가 정한 가입 양식에 따라 회원정보를
                    기입한 후 이 약관에 동의한다는 의사표시를 함으로서
                    회원가입을 신청합니다.
                  </li>
                  <li>
                    “웹 사이트”는 제1항과 같이 회원으로 가입할 것을 신청한
                    이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
                    <ol>
                      <li>
                        가입신청자가 이 약관 제7조제3항에 의하여 이전에
                        회원자격을 상실한 적이 있는 경우
                      </li>
                      <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                      <li>
                        기타 회원으로 등록하는 것이 “웹 사이트”의 기술상 현저히
                        지장이 있다고 판단되는 경우
                      </li>
                    </ol>
                  </li>
                  <li>
                    회원가입계약의 성립 시기는 “웹 사이트”의 승낙이 회원에게
                    도달한 시점으로 합니다.
                  </li>
                  <li>
                    회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한
                    기간 이내에 “웹 사이트”에 대하여 회원정보 수정 등의 방법으로
                    그 변경사항을 알려야 합니다.
                  </li>
                </ol>
              </p>

              <h3>제7조(회원 탈퇴 및 자격 상실 등)</h3>
              <p>
                <ol>
                  <li>
                    회원은 “웹 사이트”에 언제든지 탈퇴를 요청할 수 있으며 “웹
                    사이트”는 즉시 회원탈퇴를 처리합니다.
                  </li>
                  <li>
                    회원이 다음 각 호의 사유에 해당하는 경우, “웹 사이트”는
                    회원자격을 제한 및 정지시킬 수 있습니다.
                    <ol>
                      <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                      <li>
                        “웹 사이트”를 이용하여 구입한 재화 등의 대금, 기타 “웹
                        사이트”이용에 관련하여 회원이 부담하는 채무를 기일에
                        지급하지 않는 경우
                      </li>
                      <li>
                        다른 사람의 “웹 사이트” 이용을 방해하거나 그 정보를
                        도용하는 등 전자상거래 질서를 위협하는 경우
                      </li>
                      <li>
                        “웹 사이트”를 이용하여 법령 또는 이 약관이 금지하거나
                        공서양속에 반하는 행위를 하는 경우
                      </li>
                      <li>“웹 사이트”의 업무를 방해하는 행위를 하는 경우</li>
                    </ol>
                  </li>
                  <li>
                    “웹 사이트”가 회원 자격을 제한․정지 시킨 후, “웹 사이트”는
                    회원자격을 상실시킬 수 있습니다.
                  </li>
                </ol>
              </p>

              <h3>제8조(회원에 대한 통지)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”가 회원에 대한 통지를 하는 경우, 회원이 “웹
                    사이트”과 미리 약정하여 지정한 전자우편 주소로 할 수
                    있습니다.
                  </li>
                  <li>
                    “웹 사이트”가 불특정다수 회원에 대한 통지의 경우 1주일이상
                    “웹 사이트” 공지사항 게시판에 게시함으로서 개별 통지에
                    갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한
                    영향을 미치는 사항에 대하여는 개별통지를 합니다.
                  </li>
                </ol>
              </p>

              <h3>제9조(구매신청 및 개인정보 제공 동의 등)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”이용자는 “웹 사이트”상에서 다음 또는 이와 유사한
                    방법에 의하여 구매를 신청하며, “웹 사이트”은 이용자가
                    구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야
                    합니다.
                    <ol>
                      <li>재화 등의 검색 및 선택</li>
                      <li>
                        구매자의 성명, 주소, 전화번호, 전자우편주소(또는
                        이동전화번호) 등의 입력
                      </li>
                      <li>
                        약관내용, 청약철회권이 제한되는 서비스, 작업비용 등의
                        비용부담과 관련한 내용에 대한 확인
                      </li>
                      <li>
                        이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는
                        표시
                      </li>
                      <li>
                        재화등의 구매신청 및 이에 관한 확인 또는 “웹 사이트”의
                        확인에 대한 동의
                      </li>
                      <li>결제방법의 선택</li>
                    </ol>
                  </li>
                  <li>
                    “웹 사이트”가 제3자에게 구매자 개인정보를 제공할 필요가 있는
                    경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의
                    개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4)
                    개인정보를 제공받는 자의 개인정보 보유 및 이용기간을
                    구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이
                    변경되는 경우에도 같습니다.)
                  </li>
                  <li>
                    “웹 사이트”가 제3자에게 구매자의 개인정보를 취급할 수 있도록
                    업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2)
                    개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고
                    동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도
                    같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고
                    구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및
                    정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보
                    취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지
                    않아도 됩니다.
                  </li>
                </ol>
              </p>

              <h3>제10조 (계약의 성립)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 제9조와 같은 구매신청에 대하여 다음 각 호에
                    해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을
                    체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자
                    본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을
                    고지하여야 합니다.
                    <ol>
                      <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                      <li>도박, 음란물 등 불법 사이트에 해당하는 경우</li>
                      <li>
                        기타 구매신청에 승낙하는 것이 “웹 사이트” 기술상 현저히
                        지장이 있다고 판단하는 경우
                      </li>
                    </ol>
                  </li>
                  <li>
                    “웹 사이트”의 승낙이 제12조제1항의 수신확인통지형태로
                    이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                  </li>
                  <li>
                    “웹 사이트”의 승낙의 의사표시에는 이용자의 구매 신청에 대한
                    확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보
                    등을 포함하여야 합니다.
                  </li>
                </ol>
              </p>

              <h3>제11조(지급방법)</h3>
              <p>
                “웹 사이트”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음
                각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “웹 사이트”는
                이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의
                수수료도 추가하여 징수할 수 없습니다.
                <ol>
                  <li>폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
                  <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                  <li>온라인무통장입금</li>
                </ol>
              </p>

              <h3>제12조(수신확인통지․구매신청 변경 및 취소)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이용자의 구매신청이 있는 경우 이용자에게
                    수신확인통지를 합니다.
                  </li>
                  <li>
                    수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는
                    경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를
                    요청할 수 있고 “웹 사이트”는 배송 전에 이용자의 요청이 있는
                    경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만
                    이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한
                    규정에 따릅니다.
                  </li>
                </ol>
              </p>

              <h3>제13조(재화 등의 공급)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이용자와 재화 등의 공급시기에 관하여 이용자와
                    약속된 기간 이내에 재화 등을 제공할 수 있도록 기술적 작업을
                    진행합니다. 이때 “웹 사이트”는 이용자가 재화 등의 공급 절차
                    및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
                  </li>
                  <li>
                    “웹 사이트”가 약정 기간을 초과한 경우에는 그로 인한 이용자의
                    손해를 배상하여야 합니다. 다만 “웹 사이트”가 고의․과실이
                    없음을 입증한 경우에는 그러하지 아니합니다.
                  </li>
                </ol>
              </p>

              <h3>제14조(환급)</h3>
              <p>
                “웹 사이트”는 이용자가 구매신청한 재화 등이 품절 등의 사유로
                인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
                이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을
                받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를
                취합니다.
              </p>

              <h3>제15조(청약철회 등)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”와 재화등의 구매에 관한 계약을 체결한 이용자는
                    「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
                    제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은
                    때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을
                    공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
                    이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에
                    관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리
                    정함이 있는 경우에는 동 법 규정에 따릅니다.
                  </li>
                  <li>
                    이용자는 재화 등을 배송 받은 경우 다음 각 호에 해당하는
                    경우에는 반품 및 교환을 할 수 없습니다.
                    <ol>
                      <li>
                        이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
                        경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을
                        훼손한 경우에는 청약철회를 할 수 있습니다)
                      </li>
                      <li>
                        이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가
                        현저히 감소한 경우
                      </li>
                      <li>
                        시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의
                        가치가 현저히 감소한 경우
                      </li>
                      <li>
                        같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그
                        원본인 재화 등의 포장을 훼손한 경우
                      </li>
                    </ol>
                  </li>
                </ol>
              </p>

              <h3>제16조(청약철회 등의 효과)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이용자로부터 재화 등을 반환받은 경우 3영업일
                    이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우
                    “웹 사이트”가 이용자에게 재화등의 환급을 지연한때에는 그
                    지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한
                    법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한
                    지연이자를 지급합니다.
                  </li>
                  <li>
                    “웹 사이트”는 위 대금을 환급함에 있어서 이용자가 신용카드
                    또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한
                    때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화
                    등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                  </li>
                  <li>
                    청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은
                    이용자가 부담합니다. “웹 사이트”는 이용자에게 청약철회 등을
                    이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화
                    등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게
                    이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한
                    비용은 “웹 사이트”가 부담합니다.
                  </li>
                </ol>
              </p>

              <h3>제17조(개인정보보호)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이용자의 개인정보 수집시 서비스제공을 위하여
                    필요한 범위에서 최소한의 개인정보를 수집합니다.{" "}
                  </li>
                  <li>
                    “웹 사이트”는 회원가입시 구매계약이행에 필요한 정보를 미리
                    수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여
                    구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정
                    개인정보를 수집하는 경우에는 그러하지 아니합니다.
                  </li>
                  <li>
                    “웹 사이트”는 이용자의 개인정보를 수집·이용하는 때에는 당해
                    이용자에게 그 목적을 고지하고 동의를 받습니다.{" "}
                  </li>
                  <li>
                    “웹 사이트”는 수집된 개인정보를 목적외의 용도로 이용할 수
                    없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게
                    제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그
                    목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리
                    정함이 있는 경우에는 예외로 합니다.
                  </li>
                  <li>
                    “웹 사이트”가 제2항과 제3항에 의해 이용자의 동의를 받아야
                    하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및
                    전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에
                    대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의
                    내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」
                    제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며
                    이용자는 언제든지 이 동의를 철회할 수 있습니다.
                  </li>
                  <li>
                    이용자는 언제든지 “웹 사이트”가 가지고 있는 자신의
                    개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “웹
                    사이트”는 이에 대해 지체 없이 필요한 조치를 취할 의무를
                    집니다. 이용자가 오류의 정정을 요구한 경우에는 “웹 사이트”는
                    그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                  </li>
                  <li>
                    “웹 사이트”는 개인정보 보호를 위하여 이용자의 개인정보를
                    취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌
                    등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는
                    제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든
                    책임을 집니다.
                  </li>
                  <li>
                    “웹 사이트” 또는 그로부터 개인정보를 제공받은 제3자는
                    개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
                    개인정보를 지체 없이 파기합니다.
                  </li>
                  <li>
                    “웹 사이트”는 개인정보의 수집·이용·제공에 관한 동의 란을
                    미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의
                    수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를
                    구체적으로 명시하고, 필수수집항목이 아닌 개인정보의
                    수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입
                    등 서비스 제공을 제한하거나 거절하지 않습니다.
                  </li>
                </ol>
              </p>

              <h3>제19조(회원의 ID 및 비밀번호에 대한 의무)</h3>
              <p>
                <ol>
                  <li>
                    제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은
                    회원에게 있습니다.
                  </li>
                  <li>
                    회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                    안됩니다.
                  </li>
                  <li>
                    회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
                    있음을 인지한 경우에는 바로 “웹 사이트”에 통보하고 “웹
                    사이트”의 안내가 있는 경우에는 그에 따라야 합니다.
                  </li>
                </ol>
              </p>

              <h3>
                제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안 됩니다.
              </h3>
              <p>
                <ol>
                  <li>신청 또는 변경시 허위 내용의 등록</li>
                  <li>타인의 정보 도용</li>
                  <li>“웹 사이트”에 게시된 정보의 변경</li>
                  <li>
                    “웹 사이트”각 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의
                    송신 또는 게시
                  </li>
                  <li>
                    “웹 사이트” 기타 제3자의 저작권 등 지적재산권에 대한 침해
                  </li>
                  <li>
                    “웹 사이트” 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                    행위
                  </li>
                  <li>
                    외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                    반하는 정보를 웹 사이트에 공개 또는 게시하는 행위
                  </li>
                </ol>
              </p>

              <h3>제21조(연결“웹 사이트”과 피연결“웹 사이트” 간의 관계)</h3>
              <p>
                <ol>
                  <li>
                    상위 “웹 사이트”과 하위 “웹 사이트”가 하이퍼링크(예:
                    하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식
                    등으로 연결된 경우, 전자를 연결 “웹 사이트”라고 하고 후자를
                    피연결 “웹 사이트”라고 합니다.
                  </li>
                  <li>
                    연결“웹 사이트”는 피연결“웹 사이트”이 독자적으로 제공하는
                    재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을
                    지지 않는다는 뜻을 연결“웹 사이트”의 초기화면 또는 연결되는
                    시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증
                    책임을 지지 않습니다.
                  </li>
                </ol>
              </p>

              <h3>제22조(저작권의 귀속 및 이용제한)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”가 작성한 저작물에 대한 저작권 기타 지적재산권은
                    “웹 사이트”에 귀속합니다.
                  </li>
                  <li>
                    이용자는 “웹 사이트”를 이용함으로써 얻은 정보 중 “웹
                    사이트”에게 지적재산권이 귀속된 정보를 “웹 사이트”의 사전
                    승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여
                    영리목적으로 이용하거나 제3자에게 이용하게 하여서는
                    안됩니다.
                  </li>
                </ol>
              </p>

              <h3>제23조(분쟁해결)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”는 이용자가 제기하는 정당한 의견이나 불만을
                    반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
                    설치․운영합니다.
                  </li>
                  <li>
                    “웹 사이트”는 이용자로부터 제출되는 불만사항 및 의견은
                    우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
                    경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해
                    드립니다.
                  </li>
                  <li>
                    “웹 사이트”는 이용자 간에 발생한 전자상거래 분쟁과 관련하여
                    이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
                    시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
                  </li>
                </ol>
              </p>

              <h3>제24조(재판권 및 준거법)</h3>
              <p>
                <ol>
                  <li>
                    “웹 사이트”와 이용자 간에 발생한 전자상거래 분쟁에 관한
                    소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
                    경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만,
                    제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
                    거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
                  </li>
                  <li>
                    “웹 사이트”와 이용자 간에 제기된 전자상거래 소송에는
                    한국법을 적용합니다.
                  </li>
                </ol>
              </p>
            </div>
            <label>
              <input ref={inputServiceCheck} type="checkbox" />
              이용약관에 동의합니다.
            </label>
            <br />
            <br />
            {/* 개인정보처리방침 */}
            <h3>개인정보처리방침[필수]</h3>
            <div className={styles.policyPrivacy}>
              <h3>개인정보 보호정책</h3>
              <p>
                우리 헌법은 국민의 기본권인 사생활의 비밀과 자유 및 통신의
                비밀을 보장하고 있으므로 도/감청 등에의한 개인 정보 및 사생활의
                은밀한 탐지는 원칙적으로 불법입니다. 그러나 우리 사회에 횡행하는
                불법 도청, 통신상의 정보 유출로 인하여 심각한 인권 침해가
                나타나고 있고 국민 개개인의 자유로운 생활형성이 위협 받고
                있습니다.
                <br />
                이러한 기본권 침해의 소지를 원천적으로 제거함으로써 아리 장난감
                도서관회원의 프라이버시를 철저히 보호하여 정보화 사회에서의
                통신의 자유를 보장하고자 아래와 같이 개인정보보호정책을
                명시합니다. 아리 장난감 도서관 개인정보보호정책은 정부의 법률 및
                지침의 변경과 아리 장난감 도서관의 정책 변화에 따라 변경될 수
                있습니다. 회원님께서는 아리 장난감 도서관 사이트 방문 시에
                수시로 확인하시기 바랍니다.
              </p>
              <div>
                <h4>1. 개인정보의 수집목적 및 이용</h4>
                <p>
                  장난감 도서관 회원님 개인의 정보를 수집하는 목적은 아리 장난감
                  도서관 사이트를 통하여 회원님께 최적의 맞춤화된 서비스를
                  제공해드리기 위한 것입니다. 장난감 도서관는 각종의 컨텐츠를
                  무료 및 유료로 서비스해 드리고 있습니다. 이때 회원님께서
                  제공해주신 개인정보를 바탕으로 회원님께 보다 더 유용한 정보를
                  선택적으로 제공하는 것이 가능하게 됩니다.
                  <br />
                  장난감 도서관는 각종 서비스를 제공하기 위해서 광고를
                  게재합니다. 이때에도 회원님 개인에 대한 정보를 바탕으로 좀더
                  유용한 정보로서의 가치가 있는 광고를 선별적으로 전달할 수 있게
                  됩니다.
                </p>
              </div>
              <div>
                <h4>2. 수집하는 개인정보 항목 및 수집방법</h4>
                <p>
                  장난감 도서관는 최초 회원가입을 하실 때 서비스 제공을 위해
                  가장 필수적인 개인정보를 받고 있습니다. 회원가입 시에 받는
                  정보는 회원님의 아이디, 생년월일, 별명, 결혼, 패스워드, 추천인
                  아이디, 성명, e-mail, 주소, 전화번호, 휴대전화 등입니다.
                  <br />
                  이 이외에 특정 서비스를 제공하기 위하여 추가적인 정보제공을
                  요청하고 있습니다.
                  <br />
                  실명확인을 위한 휴대폰번호, 기타 서비스제공에 필요한
                  추가정보의 기재를 요청하게 됩니다. 또한 설문조사나 이벤트 시에
                  집단적인 통계분석을 위해서나 경품발송을 위한 목적으로도
                  개인정보 기재를 요청할 수 있습니다. 이때에도 기입하신 정보는
                  해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른
                  어떠한 목적으로도 사용되지 않음을 알려드립니다.
                </p>
              </div>
              <div>
                <h4>3. 개인정보의 보유 및 폐기</h4>
                <p>
                  귀하가 장난감 도서관 의 회원으로서 장난감 도서관 가 제공하는
                  서비스를 받는 동안 회원님의 개인정보는 아리 장난감 도서관에서
                  계속 보유하며 서비스 제공을 위해 이용하게 됩니다. 다만 장난감
                  도서관 의 회원이 아래의 '7. 자신의 개인정보 열람, 정정 및
                  삭제'에서 설명한 절차에 따라 ID를 삭제하거나 가입해지를 요청한
                  경우와 회원님께 사전에 알려드린 개인정보를 제공받은 목적이
                  달성된 경우에 수집된 개인의 정보는 재생할 수 없는 방법에
                  의하여 하드디스크에서 완전히 삭제되며 어떠한 용도로도 열람
                  또는 이용할 수 없도록 처리됩니다.
                </p>
              </div>
              <div>
                <h4>4. 개인정보의 제공 및 공유 </h4>
                <p>
                  원칙적으로 장난감 도서관 회원님의 개인정보를 타인 또는
                  타기업·기관에 공개하지 않습니다. 다만 회원님이 공개에 동의한
                  경우 또는 장난감 도서관 의 서비스 이용약관을 위배하는 사람이나
                  아리 장난감 도서관 의 서비스를 이용하여 타인에게 법적인 피해를
                  주거나 미풍양속을 해치는 행위를 한 사람 등에게 법적인 조치를
                  취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한
                  근거가 있는 경우는 예외로 합니다.
                  <br />
                  장난감 도서관는 보다 나은 서비스 제공을 위해 회원님의
                  개인정보를 비즈니스 파트너와 공유할 수 있습니다. 이 경우에도
                  정보수집 또는 정보제공 이전에 회원님께 비즈니스 파트너가
                  누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지 어떻게
                  보호/관리되는지 알려드리고 동의를 구하는 절차를 거치게 되며,
                  회원님께서 동의하지 않는 경우에는 추가적인 정보를 수집하거나
                  비즈니스 파트너와 공유하지 않습니다.
                  <br />
                  성별, 연령별 기타 특정 조건의 집단에 대한 광고 게재 및 발송
                  시(예, 20대 여자)에도 회원님 개인의 정보는 광고를 의뢰한
                  개인이나 단체에 제공되지 않습니다. 기타 통계처리, 학술연구,
                  시장조사를 위하여 필요한 경우에는 특정한 개인을 식별할 수 없는
                  형태로만 정보가 제공됩니다.
                </p>
              </div>
              <div>
                <h4>5. 쿠키(cookie)의 운용 및 활용</h4>
                <p>
                  회원님 개개인에게 개인화되고(personalized)
                  맞춤화된(customized) 서비스를 제공하기 위해서 장난감 도서관는
                  회원님의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를
                  사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가
                  사용자의 브라우저에게 보내는 조그마한 데이터 꾸러미로 회원님
                  컴퓨터의 하드디스크에 저장됩니다.
                  <br />
                  회원님께서 장난감 도서관 에 접속한 후 로그인(log-in)하여
                  개인화된 서비스나 맞춤화된 서비스를 이용하시기 위해서는 쿠키를
                  허용하여야 합니다. 장난감 도서관는 회원님께 적합하고 보다
                  유용한 서비스를 제공하기 위해서 쿠키를 이용하여 회원님의
                  아이디에 대한 정보를 찾아냅니다.
                  <br />
                  장난감 도서관에 접속하는 이용자의 브라우저에 고유한 쿠키를
                  부여함으로써 회원 및 비회원들의 장난감 도서관사이트 이용빈도나
                  전체 이용자수 등과 같은 이용자 규모를 파악하는데도 이용됩니다.
                  또한 장난감 도서관쇼핑에서 상품구매 시 인증을 위해서나
                  상품구매를 돕기 위해서도 쿠키 정보를 이용하며, 기타 이벤트나
                  설문조사에서 회원님의 참여 경력을 확인하기 위해서 쿠키를
                  이용하게 됩니다.
                  <br />
                  쿠키를 이용하여 회원님께서 방문한 장난감 도서관서비스의 방문
                  및 이용형태를 파악함으로써 더 유용하고 이용하기 편리한
                  서비스를 만들어 제공할 수 있게 됩니다.
                  <br />
                  회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의
                  웹브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나,
                  쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을
                  거부할 수 있습니다. 단, 쿠키의 저장을 거부하실 경우 로그인이
                  필요한 아리 장난감 도서관의 모든 서비스는 이용하실 수
                  없게됩니다.
                </p>
              </div>
              <div>
                <h4>6. 개인정보보호를 위한 기술적/제도적 관리</h4>
                <p>
                  회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님
                  계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인
                  및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
                  따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다. 또한
                  작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를
                  종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여
                  사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게
                  알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요하다고
                  하겠습니다.
                  <br />
                  장난감 도서관는 백신프로그램을 이용하여 컴퓨터바이러스에 의한
                  피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은
                  주기적으로 업데이트 되며 갑작스런 바이러스가 출현할 경우
                  백신이 나오는 즉시 이를 제공함으로써 개인정보가 훼손되는 것을
                  방지하고 있습니다.
                  <br />
                  해킹 등에 의해 회원의 개인정보가 유출되는 것을 막기 위해 현재
                  외부로부터의 침입을 차단하는 장치를 이용하여 외부로부터의
                  공격, 해킹 등을 막고 있으며, 각 서버마다 침입탐지시스템을
                  설치하여 24시간 침입을 감시하고 있습니다. 기타 회원 개인정보의
                  훼손에 대비해서 시스템과 데이터를 백업하여 만약의 사태에
                  대비하고 있습니다.
                  <br />
                  장난감 도서관는 개인정보 취급 직원을 최소한으로 제한하고
                  담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고
                  있으며, 감사위원회의 감사를 통하여 본 정책의 이행사항 및
                  담당직원의 준수여부를 확인하여 문제가 발견될 경우 바로
                  시정조치하고 있습니다.
                </p>
              </div>
              <div>
                <h4>7. 자신의 개인정보 열람, 정정 및 삭제</h4>
                <p>
                  회원님은 언제든지 등록되어 있는 회원님의 개인정보를 열람하거나
                  정정하실 수 있으며, 아이디(ID) 삭제를 요청하실 수 있습니다.
                  <br />
                  개인정보와 관련하여 불만이나 의견이 있으신 분은 개인정보
                  관리담당자에게 메일 (help@장난감 도서관.co.kr) 로 의견을
                  주시면 접수 즉시 조치하고 처리결과를 통보해 드리겠습니다.
                </p>
              </div>
              <div>
                <h4>8. 어린이의 개인정보보호</h4>
                <p>
                  {" "}
                  만14세 미만의 어린이는 자신에 대한 정보를 다른 사람에게 함부로
                  보내면 안되며, 보내기 전에 반드시 부모님의 허락을 받아야
                  합니다.
                  <br />
                  회원님 계정의 비밀번호에 대한 보안을 유지할 책임은 회원님
                  자신에게 있습니다. 장난감 도서관에서 메일 또는 기타 방법으로
                  회원님께 비밀번호를 질문하는 경우는 절대 없으므로 어떠한
                  경우에도 비밀번호를 알려주지 마십시오. 로그온(log-on)한
                  상태에서는 주위의 다른 사람에게 개인정보가 유출되지 않도록
                  특별히 주의를 기울이시기 바랍니다.
                </p>
              </div>
            </div>
            <label>
              <input ref={inputPrivacyCheck} type="checkbox" />
              개인정보처리방침에 동의합니다.
            </label>
          </div>
          <div className={styles.buttonWrapper}>
            <input type="submit" value={"가입"} />
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default SignUp;
