import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { backend_origin } from "../../../configs/config";

import Veditor from "../../../components/Editor/Veditor";
import AdminLayout from "../../../components/Layout/AdminLayout";

import styles from "./adminToyCreate.module.css";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";

export default function AdminToyCreate() {
  const [toy, setToy] = useState({
    name: "",
    components: "",
    company: "",
    ageOfUse: "",
    mainCategory: "",
    subCategory: "",
    code: "",
    quantity: 0,
    isNew: true,
  });
  const [content, setContent] = useState("");
  const [imgFileURL, setImgFileURL] = useState(null);
  const imgRef = useRef();

  const navigate = useNavigate();

  // 이미지 업로드 input의 onChange
  const saveImgFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFileURL(reader.result);
    };
  };

  const postToy = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const formData = new FormData();
    formData.append("name", toy.name);
    formData.append("components", toy.components);
    formData.append("company", toy.company);
    formData.append("ageOfUse", toy.ageOfUse);
    formData.append("mainCategory", toy.mainCategory);
    formData.append("subCategory", toy.subCategory);
    formData.append("code", toy.code);
    formData.append("content", content);
    formData.append("quantity", toy.quantity);
    formData.append("isNew", toy.isNew);
    formData.append("image", imgRef.current.files[0]);
    const response = await fetch(`${backend_origin}/api/toy/`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      const json = await response.json();
      alert("등록되었습니다.");
      navigate(`/adminpage/toy`);
    } else if (response.status === 400) {
      const json = await response.json();
      console.log(json);
      alert("모두 입력해주세요.");
    }
  };

  const changeToyState = (key, value) => {
    const tmpDict = { ...toy };
    tmpDict[key] = value;
    setToy(tmpDict);
  };

  return (
    <AdminLayout section={"toy"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>장난감 생성</div>
        <table className={styles.table}>
          <tr>
            <td>이름</td>
            <td>
              <input
                value={toy.name}
                onChange={(e) => changeToyState("name", e.target.value)}
                placeholder="이름을 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>구성품</td>
            <td>
              <input
                value={toy.components}
                onChange={(e) => changeToyState("components", e.target.value)}
                placeholder="구성품을 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>제조사</td>
            <td>
              <input
                value={toy.company}
                onChange={(e) => changeToyState("company", e.target.value)}
                placeholder="제조사를 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>사용연령</td>
            <td>
              <input
                value={toy.ageOfUse}
                onChange={(e) => changeToyState("ageOfUse", e.target.value)}
                placeholder="사용연령을 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>대분류</td>
            <td>
              <input
                value={toy.mainCategory}
                onChange={(e) => changeToyState("mainCategory", e.target.value)}
                placeholder="대분류를 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>소분류</td>
            <td>
              <input
                value={toy.subCategory}
                onChange={(e) => changeToyState("subCategory", e.target.value)}
                placeholder="소분류를 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>바코드</td>
            <td>
              <input
                value={toy.code}
                onChange={(e) => changeToyState("code", e.target.value)}
                placeholder="바코드를 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <td>대표 이미지</td>
            <td>
              {imgFileURL ? (
                <>
                  <img
                    className={styles.toyThumbnailImage}
                    src={imgFileURL}
                    alt="장난감 대표 이미지"
                  />
                  <br />
                </>
              ) : null}
              <label
                className={styles.toyThumbnailImageLabel}
                htmlFor="toyThumnailImg"
              >
                장난감 대표 이미지 설정
              </label>
              <input
                id="toyThumnailImg"
                style={{ display: "none" }}
                ref={imgRef}
                type="file"
                accept="image/*"
                onChange={saveImgFile}
              />
            </td>
          </tr>
          <tr>
            <td>대여가능 수량</td>
            <td>
              <input
                value={toy.quantity}
                onChange={(e) =>
                  changeToyState("quantity", Number(e.target.value))
                }
                type="number"
                min="1"
                defaultValue="1"
              />
            </td>
          </tr>
          <tr>
            <td>신규여부</td>
            <td>
              <input
                checked={toy.isNew}
                onChange={(e) => changeToyState("isNew", !toy.isNew)}
                type="checkbox"
              />
            </td>
          </tr>
        </table>
        <div className={styles.editorDiv}>
          <div style={{ paddingBottom: 80 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="toy_content"
            />
          </div>
        </div>
        <div className={styles.submit}>
          <div>
            <button onClick={postToy}>저장</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
