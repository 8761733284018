import { Map, MapMarker } from "react-kakao-maps-sdk";

import Layout from "../components/Layout/Layout";
import tmp_img2 from "../images/tmp_img2.jpg";
import MainBanner from "../components/MainBanner/MainBanner";

import styles from "./styles/direction.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function Direction() {
  return (
    <Layout>
      <MainBanner image={tmp_img2} title="오시는 길" />
      <div className="wrapper">
        <div className={styles.map_wrapper}>
          <Map
            center={{ lat: 37.22831943359446, lng: 128.81911446721864 }} // 지도의 중심 좌표
            style={{ width: "100%", height: "500px" }} // 지도 크기
            level={3} // 지도 확대 레벨
          >
            <MapMarker
              position={{ lat: 37.228626, lng: 128.8193392 }}
            ></MapMarker>
          </Map>
        </div>
        <div className={styles.info_wrapper}>
          <div>
            <span>
              <span className={styles.caret}><FontAwesomeIcon icon={faCaretRight} /></span>
              &nbsp;영업시간 안내
            </span>
            <table>
              <tbody>
                <tr>
                  <td>평일</td>
                  <td>09:00 ~ 18:00</td>
                </tr>
                <tr>
                  <td>주말</td>
                  <td>정기휴무</td>
                </tr>
                <tr>
                  <td>점심시간</td>
                  <td>12:00 ~ 13:00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <span>
              <span className={styles.caret}><FontAwesomeIcon icon={faCaretRight} /></span>
              &nbsp;강원특별자치도 정선군 사북읍 사북 6길 12-6
            </span>
            <table>
              <tbody>
                <tr>
                  <td>TEL</td>
                  <td>033·592·1310~1311</td>
                </tr>
                <tr>
                  <td>FAX</td>
                  <td>033·592·1312</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
