import { useEffect, useState, useMemo, useRef } from "react";
import { backend_origin, fileMaxSize } from "../../configs/config";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Veditor = ({ content, setContent, placeholder, to }) => {
  const quillRef = useRef();
  // 이미지 핸들러
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.addEventListener("change", async () => {
      const file = input.files[0];
      if (file.size > fileMaxSize) {
        alert(`파일의 허용 최대용량은 ${fileMaxSize / 1024 / 1024}MB입니다.`);
        return;
      }
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);
      try {
        // 파일명을 "image/Date.now()"로 저장
        const imageName = Date.now();
        const fileExtension = file.name.split(".").pop();
        const formData = new FormData();
        formData.append("image", file, `${imageName}.${fileExtension}`);
        formData.append("to", to);

        const response = await fetch(`${backend_origin}/api/upload/`, {
          method: "POST",
          body: formData,
        });

        // 이미지가 업로드되었다면
        if (response.status === 201) {
          const url = `${backend_origin}/media/${to}/${imageName}.${fileExtension}`;
          // 이미지 URL 에디터에 삽입
          editor.insertEmbed(range.index, "image", url);
          // URL 삽입 후 커서를 이미지 뒷 칸으로 이동
          editor.setSelection(range.index + 1);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  // quill에서 사용할 모듈
  // useMemo를 사용하여 modules가 렌더링 시 에디터가 사라지는 버그를 방지
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }, "link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  return (
    <ReactQuill
      style={{ width: "100%", height: "600px" }}
      placeholder={placeholder}
      theme="snow"
      ref={quillRef}
      value={content}
      onChange={setContent}
      modules={modules}
    />
  );
};

export default Veditor;
