import styles from "./AdminHeader.module.css";
import { Link } from "react-router-dom";

import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../functions/account";

const AdminHeader = () => {
  return (
    <>
      <div className={styles.navbar_wrapper}>
        <header className={styles.navbar}>
          <div style={{ visibility: "hidden" }}>
            <span className={styles.btn_wrapper}>
              <Link to="/">
                <FontAwesomeIcon icon={faHouse} />
              </Link>
              <button className={styles.logoutButton} onClick={logout}>
                로그아웃
              </button>
            </span>
          </div>
          <Link to="/adminPage" className={styles.logo}>
            ADMIN PAGE
          </Link>
          <span className={styles.btn_wrapper}>
            <Link to="/">
              <FontAwesomeIcon icon={faHouse} />
            </Link>
            <button className={styles.logoutButton} onClick={logout}>
              로그아웃
            </button>
          </span>
        </header>
      </div>
    </>
  );
};

export default AdminHeader;
