import { backend_origin } from "../../../configs/config";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import AdminLayout from "../../../components/Layout/AdminLayout";
import styles from "./adminNotice.module.css";

export default function AdminNotice() {
  const [originNoticeList, setOriginNoticeList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const inputSearchRef = useRef(null);

  const navigate = useNavigate();

  const getNoticeList = async () => {
    const response = await fetch(`${backend_origin}/api/notice/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setNoticeList(json);
    setOriginNoticeList(json);
  };

  useEffect(() => {
    getNoticeList();

    return () => {};
  }, []);

  return (
    <AdminLayout section={"notice"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>공지사항 목록</div>
        <div className={styles.header_wrapper}>
          <div className={styles.search_wrapper}>
            <input ref={inputSearchRef} placeholder="검색어 입력" />
            <button
              className={styles.schBtn}
              onClick={() => {
                let keyword = inputSearchRef.current.value;
                const noticeList_tmp = [...originNoticeList];
                const filternoticeList = noticeList_tmp.filter((notice) =>
                  notice.title.includes(keyword)
                );

                setNoticeList(filternoticeList);
              }}
            >
              검색
            </button>
          </div>
          <span>
            <button
              onClick={() => {
                navigate("/adminpage/noticeCreate");
              }}
            >
              생성
            </button>
          </span>
        </div>
        <table className={styles.notice_list}>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>만든 날짜</th>
            </tr>
          </thead>
          <tbody>
            {noticeList.map((notice, idx) => {
              return (
                <tr
                  key={idx}
                  className="Raw"
                  onClick={() => {
                    navigate(`/adminPage/notice/${notice.id}`);
                  }}
                >
                  <td className={styles.id}>{notice.id}</td>
                  <td className={styles.name}>{notice.title}</td>
                  <td className={styles.date}>
                    {moment(notice.created_at).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
