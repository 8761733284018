import styles from "./DropdownForSidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function DropdownForSidebar({
  title,
  itemList,
  onlyLink,
  selected,
  selectedTab,
  setSelectedTab,
}) {
  if (onlyLink) {
    return (
      <>
        <div className={styles.tab}>
          <a href={onlyLink}>{title}</a>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={styles.tab}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (selectedTab === title) {
            setSelectedTab(null);
          } else {
            setSelectedTab(title);
          }
        }}
      >
        <span style={{ color: selected ? "#3d69c0" : null }}>{title} </span>
        {selected ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </div>
      <div
        className={styles.itemWrapper}
        style={{
          height: selected ? "unset" : null,
          visibility: selected ? "visible" : null,
        }}
      >
        {itemList[title].map((item, index) => {
          return (
            <div key={`${title}_${item.itemName}`}>
              <a href={item.itemLink}>{item.itemName}</a>
            </div>
          );
        })}
      </div>
    </>
  );
}
