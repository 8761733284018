import { useNavigate } from "react-router-dom";
import styles from "./AdminSidebar.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faBell,
  faUserPlus,
  faUsersGear,
  faCar,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";

const AdminSidebar = ({ section }) => {
  const navigate = useNavigate();
  const sideBarNavigate = (to) => {
    navigate("/adminPage/" + to);
  };

  return (
    <div className={styles.sideBarWrapper}>
      <div
        className={styles.sideBarMenu}
        onClick={() => {
          sideBarNavigate("toy");
        }}
      >
        {section === "toy" ? (
          <>
            <span className={styles.title_logo_selected}>
              <FontAwesomeIcon icon={faCar} width={15} />
            </span>
            <span className={styles.title_selected}>장난감관리</span>
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={styles.sideBarSelected}
            />
          </>
        ) : (
          <>
            <span className={styles.title_logo}>
              <FontAwesomeIcon icon={faCar} width={15} />
            </span>
            <span className={styles.title}>장난감관리</span>
          </>
        )}
      </div>
      <div
        className={styles.sideBarMenu}
        onClick={() => {
          sideBarNavigate("notice");
        }}
      >
        {section === "notice" ? (
          <>
            <span className={styles.title_logo_selected}>
              <FontAwesomeIcon icon={faBell} width={15} />
            </span>
            <span className={styles.title_selected}>공지사항</span>
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={styles.sideBarSelected}
            />
          </>
        ) : (
          <>
            <span className={styles.title_logo}>
              <FontAwesomeIcon icon={faBell} width={15} />
            </span>
            <span className={styles.title}>공지사항</span>
          </>
        )}
      </div>
      <div
        className={styles.sideBarMenu}
        onClick={() => {
          sideBarNavigate("board");
        }}
      >
        {section === "board" ? (
          <>
            <span className={styles.title_logo_selected}>
              <FontAwesomeIcon icon={faClipboard} width={15} />
            </span>
            <span className={styles.title_selected}>자유게시판</span>
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={styles.sideBarSelected}
            />
          </>
        ) : (
          <>
            <span className={styles.title_logo}>
              <FontAwesomeIcon icon={faClipboard} width={15} />
            </span>
            <span className={styles.title}>자유게시판</span>
          </>
        )}
      </div>
      <div
        className={styles.sideBarMenu}
        onClick={() => {
          sideBarNavigate("review");
        }}
      >
        {section === "review" ? (
          <>
            <span className={styles.title_logo_selected}>
              <FontAwesomeIcon icon={faUserPlus} width={15} />
            </span>
            <span className={styles.title_selected}>후기목록</span>
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={styles.sideBarSelected}
            />
          </>
        ) : (
          <>
            <span className={styles.title_logo}>
              <FontAwesomeIcon icon={faUserPlus} width={15} />
            </span>
            <span className={styles.title}>후기목록</span>
          </>
        )}
      </div>
      <div
        className={styles.sideBarMenu}
        onClick={() => {
          sideBarNavigate("user");
        }}
      >
        {section === "user" ? (
          <>
            <span className={styles.title_logo_selected}>
              <FontAwesomeIcon icon={faUsersGear} width={15} />
            </span>
            <span className={styles.title_selected}>계정관리</span>
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={styles.sideBarSelected}
            />
          </>
        ) : (
          <>
            <span className={styles.title_logo}>
              <FontAwesomeIcon icon={faUsersGear} width={15} />
            </span>
            <span className={styles.title}>계정관리</span>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminSidebar;
