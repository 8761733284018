import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { backend_origin } from "../configs/config";
import moment from "moment";

import Carousel from "../components/Carousel/Carousel";
import Layout from "../components/Layout/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import carousel1 from "../images/carousel1.jpg";
import carousel2 from "../images/carousel2.jpg";
import carousel3 from "../images/carousel3.jpg";
import carousel4 from "../images/carousel4.jpg";
import carousel5 from "../images/carousel5.jpg";
import introduce from "../images/home/introduce.png";
import toy from "../images/home/toy.png";
import info from "../images/home/info.png";
import preparingImage from "../images/preparingImage.png";

import styles from "./styles/home.module.css";

function Home() {
  const [toyList, setToyList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const movePage = useNavigate();

  const getToyList = async () => {
    const response = await fetch(`${backend_origin}/api/toy/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    const fiveToys = json.filter((toy) => toy.isNew === true).slice(-5);
    setToyList(fiveToys);
  };

  const getNoticeList = async () => {
    const response = await fetch(`${backend_origin}/api/notice/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      setNoticeList(json);
    }
  };

  useEffect(() => {
    getToyList();
    getNoticeList();

    return () => {};
  }, []);

  return (
    <Layout>
      <Carousel
        images={[carousel1, carousel2, carousel3, carousel4, carousel5]}
      />
      <div className="wrapper">
        <div className={styles.content_wrapper}>
          <div className={styles.quickPage}>
            <a onClick={() => movePage("/jstoy_introduce")}>
              <img src={introduce} alt="장난감도서관 소개" />
              <span>장난감도서관 소개</span>
            </a>
            <a onClick={() => movePage("/search")}>
              <img src={toy} alt="장난감 검색" />
              <span>장난감 검색</span>
            </a>
            <a onClick={() => movePage("/info")}>
              <img src={info} alt="이용안내" />
              <span>이용안내</span>
            </a>
          </div>
          <div className={styles.toy_list}>
            <div className={styles.toy_list_title}>
              <span>+ 더 보기</span>
              <h2>신규 장난감 목록</h2>
              <a onClick={() => movePage("/new")}>+ 더 보기</a>
            </div>
            <div className={styles.toys}>
              {toyList.map((toy, index) => {
                return (
                  <div
                    className={styles.toy}
                    key={`Toy-${index}`}
                    onClick={() => {
                      movePage(`/toy/${toy.id}`);
                    }}
                  >
                    <div className={styles.toy_name_wrapper}>
                      <h2>{toy.name}</h2>
                    </div>
                    <div className={styles.toy_img_wrapper}>
                      <img
                        className={styles.toyImg}
                        src={
                          toy.image.length !== 0
                            ? `${backend_origin}${toy.image[0].image}`
                            : preparingImage
                        }
                      />
                    </div>
                    {/* <div className={styles.toy_detail_wrapper}>
                      <p className={styles.toy_category}>{toy.subCategory}</p>
                      <p className={toy.quantity !== 0 ? styles.toy_quantity : styles.toy_is_zero}>
                        {toy.quantity}개 대여가능
                      </p>
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.noticeAndinfo}>
            <div className={styles.notice_wrapper}>
              <h3>공지사항</h3>
              <div className={styles.notices}>
                {noticeList.map((notice, index) => {
                  return (
                    <div
                      key={`notice_${index}`}
                      className={styles.notice}
                      onClick={() => {
                        movePage(`/notice/${notice.id}`);
                      }}
                    >
                      <span>{notice.id}</span>
                      <span>{notice.title}</span>
                      <span>
                        {moment(notice.created_at).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.info_wrapper}>
              <h3>안내사항</h3>
              <table className={styles.info}>
                <tbody>
                  <tr>
                    <td className={styles.address} colSpan={2}>
                      <span>
                        <FontAwesomeIcon icon={faCaretRight} />
                      </span>
                      &nbsp;강원특별자치도 정선군 사북읍 사북 6길 12-6
                    </td>
                  </tr>
                  <tr>
                    <td>평일(화~금)</td>
                    <td>13:00 ~ 20:00</td>
                  </tr>
                  <tr>
                    <td>주말(토)</td>
                    <td>10:00 ~ 17:00</td>
                  </tr>
                  <tr>
                    <td>일, 월</td>
                    <td>정기휴무</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
