import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { backend_origin } from "../../configs/config";

import Layout from "../../components/Layout/Layout";
import Veditor from "../../components/Editor/Veditor";

import styles from "../styles/reviewUpdate.module.css";
import { getCookie } from "../../configs/cookie";
import { checkLogined, refreshToken } from "../../functions/account";
import { getStar } from "../../functions/getStar";

export default function ReviewUpdate() {
  const navigate = useNavigate();
  const params = useParams();
  const articleId = params.id;

  const inputTitleRef = useRef(null);
  const [selectedReviewRating, setSelectedReviewRating] = useState(null);
  const [content, setContent] = useState("");

  const getArticleDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/review/${articleId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 후기입니다.");
      navigate("/review");
      return;
    }
    const json = await response.json();
    inputTitleRef.current.value = json.reviewTitle;
    setSelectedReviewRating(json.rating);
    setContent(json.reviewContent);
  };

  const updateArticle = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/review/${articleId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        reviewTitle: inputTitleRef.current.value,
        reviewContent: content,
        rating: selectedReviewRating,
      }),
    });
    if (response.status === 200) {
      alert("후기가 수정되었습니다.");
      navigate(`/review/${articleId}`);
      return;
    } else if (response.status === 400) {
      alert("제목과 내용, 평점을 모두 입력해주세요.");
    }
  };
  const deleteArticle = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh == undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/review/${articleId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 204) {
      alert("삭제되었습니다.");
      navigate(`/review`);
      return;
    }
  };

  const handleRatingChange = (e) => {
    setSelectedReviewRating(Number(e.target.value));
  };

  useEffect(() => {
    const result = checkLogined();
    if (result === false) {
      alert("로그인 후 이용하세요.");
      navigate("/login");
    }
    getArticleDetail();
  }, []);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>후기 수정</h1>
        <div className={styles.editor_div}>
          <div>
            <input ref={inputTitleRef} placeholder="제목을 입력해주세요." />
          </div>
          <div className={styles.starList}>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={1}
                checked={selectedReviewRating === 1}
                onChange={handleRatingChange}
              />
              {getStar(1)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={2}
                checked={selectedReviewRating === 2}
                onChange={handleRatingChange}
              />
              {getStar(2)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={3}
                checked={selectedReviewRating === 3}
                onChange={handleRatingChange}
              />
              {getStar(3)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={4}
                checked={selectedReviewRating === 4}
                onChange={handleRatingChange}
              />
              {getStar(4)}
            </label>
            <label style={{ color: "#ffa500" }}>
              <input
                type="radio"
                name="options"
                value={5}
                checked={selectedReviewRating === 5}
                onChange={handleRatingChange}
              />
              {getStar(5)}
            </label>
          </div>
          <div style={{ paddingBottom: 40 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="review"
            />
          </div>
        </div>
      </div>
      <div className={styles.btn_wrapper}>
        <button className={styles.saveBtn} onClick={updateArticle}>
          저장
        </button>
        <button className={styles.delBtn} onClick={deleteArticle}>
          삭제
        </button>
      </div>
    </Layout>
  );
}
