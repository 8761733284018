import styles from "./Footer.module.css";
import logo from "../../images/logo.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapperPC}>
        <div className={styles.logo}>
          <img src={logo} alt="아리장난감도서관" />
        </div>
        <div className={styles.break_line}></div>
        <div className={styles.company_info}>
          <p>상호명：정선 아리 장난감 도서관</p>
          <p>사업자등록번호 : 225-82-18162</p>
          <p>대표자：황진호</p>
          <p>사업장 주소지 : 강원특별자치도 정선군 사북읍 사북6길 12-6</p>
          <p>이메일 : sbyc1310@hanmail.net</p>
          <p>연락처 : {`033)592.1310~1311 / Fax.033)592.1312`}</p>
          <br />
          <p>Copyright © 2023 정선 아리 장난감 도서관</p>
          <p>All rights Reserved.</p>
        </div>
      </div>
      <div className={styles.wrapperMobile}>
        <div className={styles.logo}>
          <img src={logo} alt="아리장난감도서관" />
        </div>
        <div className={styles.company_info}>
          <br />
          <p>상호명：정선 아리 장난감 도서관</p>
          <p>사업자등록번호 : 225-82-18162</p>
          <p>대표자：황진호</p>
          <p>사업장 주소지 : 강원특별자치도 정선군 사북읍 사북6길 12-6</p>
          <p>이메일 : sbyc1310@hanmail.net</p>
          <p>연락처 : {`033)592.1310~1311 / Fax.033)592.1312`}</p>
          <br />
          <p>Copyright © 2023 정선 아리 장난감 도서관</p>
          <p>All rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
