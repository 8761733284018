import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { backend_origin } from "../../../configs/config";

import Veditor from "../../../components/Editor/Veditor";
import AdminLayout from "../../../components/Layout/AdminLayout";

import styles from "./adminNoticeCreate.module.css";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";

export default function AdminNoticeCreate() {
  const noticeTitleRef = useRef(null);
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  const createNotice = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const response = await fetch(`${backend_origin}/api/notice/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        title: noticeTitleRef.current.value,
        content: content,
      }),
    });
    if (response.status === 200) {
      const json = await response.json();
      alert("등록되었습니다.");
      navigate(`/adminPage/notice`);
    } else if (response.status === 400) {
      alert("제목과 내용을 모두 입력해주세요.");
    }
  };

  return (
    <AdminLayout section={"notice"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>공지사항 생성</div>
        <div className={styles.editorDiv}>
          <div>
            <input
              className={styles.titleInput}
              ref={noticeTitleRef}
              placeholder="제목을 입력해주세요."
            />
          </div>
          <div style={{ paddingBottom: 80 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="notice"
            />
          </div>
        </div>
        <div className={styles.submit}>
          <div>
            <button onClick={createNotice}>저장</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
