import moment from "moment/moment";
import { backend_origin } from "../../../configs/config";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import styles from "./adminUser.module.css";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";

export default function AdminUser() {
  const [userList, setUserList] = useState([]);

  const navigate = useNavigate();

  const getUserList = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("접근 불가합니다.");
        navigate("/");
        return;
      }
      access = result;
    }
    const response = await fetch(`${backend_origin}/api/user/list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    const json = await response.json();
    console.log(json);
    setUserList(json);
  };

  useEffect(() => {
    getUserList();

    return () => {};
  }, []);

  return (
    <AdminLayout section={"user"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>계정 목록</div>
        <table className={styles.listTable}>
          <thead>
            <tr>
              <th>No.</th>
              <th>아이디</th>
              <th>이름</th>
              <th>권한</th>
              <th>가입 날짜</th>
              <th>최근 로그인</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((user, idx) => {
              return (
                <tr
                  key={idx}
                  className="Raw"
                  onClick={() => {
                    navigate(`/adminPage/user/${user.id}`);
                  }}
                >
                  <td className={styles.id}>{user.id}</td>
                  <td className={styles.username}>{user.username}</td>
                  <td className={styles.name}>{user.name}</td>
                  <td className={styles.permission}>
                    {user.isManager ? "관리자" : "일반"}
                  </td>
                  <td className={styles.dateJoined}>
                    {moment(user.date_joined).format("YYYY.MM.DD HH:mm")}
                  </td>
                  <td className={styles.lastLogin}>
                    {user.last_login
                      ? moment(user.last_login).format("YYYY.MM.DD HH:mm")
                      : `-----`}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
