import Layout from "../components/Layout/Layout";
import tmp_img2 from "../images/tmp_img2.jpg";
import MainBanner from "../components/MainBanner/MainBanner";
import styles from "./styles/info.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function Info() {
  return (
    <Layout>
      <MainBanner image={tmp_img2} title="이용안내" />
      <div className="wrapper">
        <div className={styles.info_wrapper}>
          <div className={styles.content_wrapper}>
            <span><b><FontAwesomeIcon icon={faCaretRight} /></b> 아리 장난감 도서관 이용 수칙</span>
            <div className={styles.writing}>
              <ol>
                <li>
                  운영시간은 화~금(평일) 13:00~20:00 토(주말) 10:00~17:00<br />
                  <span className={styles.explanation}>※ 일&월 및 법정공휴일은 휴관입니다.</span><br />
                  <span className={styles.explanation}>※ 직원교육, 연수, 그 외 사북청소년장학센터 기관장이 지정한 휴관일은 휴관입니다.</span><br />
                </li>
                <li>
                  본인(회원) 이외 친지 및 지인에게 회원권을 절대 양도할 수 없습니다.<br />
                </li>
                <li>
                  모든 장난감들은 실내용입니다. 실외에서는 절대 가지고 놀 수 없으며 발견 즉시 장난감도서관의 모든 장난감 대여가 정지됩니다.<br />
                </li>
                <li>
                  대여한 장난감은 사북청소년장학센터 및 아리키즈카페 내에서 가지고 놀 수 없습니다.<br />
                </li>
                <li>
                  대여 전 장난감도서관 내에서 장난감을 꺼내어 가지고 놀 수 없으며 대여 후 가정에서만 사용 가능 합니다.<br />
                </li>
                <li>
                  반납은 현장반납만 가능합니다. 배달반납, 택배반납은 절대불가입니다.<br />
                  <span className={styles.explanation}>※ 운영시간 외 반납도 인정하지 않습니다. 날짜, 시간 꼭 맞춰주세요!</span><br />
                </li>
                <li>
                  대여료는 따로 발생하지 않으나 연체 시 장난감 1점 당 1,000원(일천원)의 연체료가 부과됩니다.<br />
                  <span className={styles.explanation}>※ 연체료 부과는 모두가 장난감도서관의 물품을 함께 나누기 위함입니다.</span><br />
                </li>
                <li>
                  대여 물품 이용 중 이상 발견 시 즉시 연락주셔야합니다.<br />
                  <span className={styles.explanation}>※ 24시간 후 이상 발견 알려주실시 법적 책임은 회원(보호자)에게 있습니다.</span><br />
                </li>
                <li>
                  장난감대여 후 가정에서 놀이 시 발생한 아동의 안전사고의 책임은 회원(보호자)에게 있습니다.<br />
                </li>
                <li>
                  장난감도서관 내 사진촬영은 타인에게 피해줄 수 있으므로 금지 합니다.<br />
                </li>
              </ol>
              <div className={styles.caution}>
                <span>모든 장난감은 정선군의 소중한 자산입니다. 함께 사용하는 것이므로 깨끗하게 사용 부탁드립니다!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
