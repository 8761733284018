import moment from "moment";

import { backend_origin } from "../../configs/config";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import Layout from "../../components/Layout/Layout";

import tmp_img2 from "../../images/tmp_img2.jpg";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/board.module.css";

export default function Board() {
  const navigate = useNavigate();
  const [originArticles, setOriginArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const inputSearchRef = useRef(null);

  const getArticles = async () => {
    const response = await fetch(`${backend_origin}/api/board/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      setArticles(json);
      setOriginArticles(json);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title={"자유게시판"} />
      <div className={styles.wrapper}>
        <div className={styles.tableFunctionDiv}>
          <div className={styles.search_wrapper}>
            <input ref={inputSearchRef} placeholder="검색어 입력" />
            <button
              className={styles.schBtn}
              onClick={() => {
                let keyword = inputSearchRef.current.value;
                const boardList_tmp = [...originArticles];
                const filterboardList = boardList_tmp.filter((board) =>
                  board.title.includes(keyword)
                );

                setArticles(filterboardList);
              }}
            >
              검색
            </button>
          </div>
        </div>
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article, index) => (
              <tr
                key={`article_${index}`}
                onClick={() => {
                  navigate(`/board/${article.id}`);
                }}
              >
                <td>{article.id}</td>
                <td>{article.title}</td>
                <td>{article.author.name}</td>
                <td>{moment(article.created_at).format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.write_wrapper}>
          <button
            className={styles.writeButton}
            onClick={() => {
              navigate("write");
            }}
          >
            글쓰기
          </button>
        </div>
      </div>
      {/* <div className={styles.wrapperMobile}>
        <div className={styles.tableFunctionDiv}>
          <button
            className={styles.writeButton}
            onClick={() => {
              navigate("write");
            }}
          >
            글쓰기
          </button>
        </div>
        {articles.map((article, index) => (
          <div
            className={styles.articleM}
            key={`article_${index}`}
            onClick={() => {
              navigate(`/board/${article.id}`);
            }}
          >
            <p>{article.title}</p>
            <span>
              {article.author.name} -{" "}
              {moment(article.created_at).format("YYYY-MM-DD")}
            </span>
          </div>
        ))}
      </div> */}
    </Layout>
  );
}
