import styles from "./MainBanner.module.css";

export default function MainBanner({ image, title }) {
  return (
    <div className={styles.mainBanner}>
      <img className={styles.mainImage} src={image} alt="" />
      <div className={styles.mainBannerInner}>
        <div className={styles.mainBannerContent}>
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
}
