import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";
import { useEffect, useState } from "react";
import styles from "./adminReviewDetail.module.css";
import { getStar } from "../../../functions/getStar";

export default function AdminReviewDetail() {
  const params = useParams();
  const reviewId = params.id;
  const navigate = useNavigate();

  const [review, setReview] = useState(null);

  const getReviewDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/review/${reviewId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않습니다.");
      navigate("/adminPage/review");
      return;
    }
    const review = await response.json();
    review.reviewContent = review.reviewContent.replace(
      `img `,
      `img style="max-width:100%;"`
    );
    setReview(review);
  };

  useEffect(() => {
    getReviewDetail();
  }, []);

  return (
    <AdminLayout section={"review"}>
      {review && (
        <div className={styles.contents_wrapper}>
          <div className={styles.header}>후기 상세</div>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>제목</td>
                <td className={styles.title}>
                  <div>{review.reviewTitle}</div>
                </td>
              </tr>
              <tr>
                <td>별점</td>
                <td>{getStar(review.rating)}</td>
              </tr>
              <tr>
                <td>내용</td>
                <td className={styles.content}>
                  <div
                    className={`view ql-editor`}
                    dangerouslySetInnerHTML={{ __html: review.reviewContent }}
                  ></div>
                </td>
              </tr>
              <tr>
                <td>작성자</td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>이름</td>
                        <td>{review.author.name}</td>
                      </tr>
                      <tr>
                        <td>아이디</td>
                        <td>{review.author.username}</td>
                      </tr>
                      <tr>
                        <td>이메일</td>
                        <td>{review.author.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </AdminLayout>
  );
}
