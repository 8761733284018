import "./pages/styles/global.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/signUp";
import MyPage from "./pages/mypage";
import Board from "./pages/board/board";
import BoardWrite from "./pages/board/boardWrite";
import BoardDetail from "./pages/board/boardDetail";
import BoardUpdate from "./pages/board/boardUpdate";
import AdminHome from "./pages/adminPages/adminHome";
import AdminToy from "./pages/adminPages/toy/adminToy";
import AdminToyCreate from "./pages/adminPages/toy/adminToyCreate";
import AdminToyDetail from "./pages/adminPages/toy/adminToyDetail";
import NewToys from "./pages/toy/newToys";
import ToyDetail from "./pages/toy/toyDetail";
import Notice from "./pages/notice/notice";
import AdminNotice from "./pages/adminPages/notice/adminNotice";
import AdminNoticeCreate from "./pages/adminPages/notice/adminNoticeCreate";
import AdminNoticeDetail from "./pages/adminPages/notice/adminNoticeDetail";

import SearchToys from "./pages/toy/searchToys";
import NoticeDetail from "./pages/notice/noticeDetail";
import Introduce from "./pages/introduce";
import JstoyIntroduce from "./pages/jstoyIntroduce";
import Direction from "./pages/direction";
import CallNumber from "./pages/callNumber";
import Info from "./pages/info";
import Terms from "./pages/terms";
import Review from "./pages/review/review";
import AdminUser from "./pages/adminPages/user/adminUser";
import ReviewDetail from "./pages/review/reviewDetail";
import ReviewWrite from "./pages/review/reviewWrite";
import ReviewUpdate from "./pages/review/reviewUpdate";
import AdminReview from "./pages/adminPages/review/adminReview";
import AdminReviewDetail from "./pages/adminPages/review/adminReviewDetail";
import AdminUserDetail from "./pages/adminPages/user/adminUserDetail";
import Privacy from "./pages/privacy/privacy";
import AdminBoard from "./pages/adminPages/board/adminBoard";
import AdminBoardDetail from "./pages/adminPages/board/adminBoardDetail";
import NotFound from "./pages/404/404";
import AdminToyExcelUpload from "./pages/adminPages/toy/adminToyExcelUpload";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* 인증 관련 */}
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/mypage" element={<MyPage />} />

        {/* 대여소소개 탭 관련 */}
        <Route path="/introduce" element={<Introduce />} />
        <Route path="/jstoy_introduce" element={<JstoyIntroduce />} />
        <Route path="/direction" element={<Direction />} />
        <Route path="/callNumber" element={<CallNumber />} />

        {/* 이용안내 탭 관련 */}
        <Route path="/info" element={<Info />} />
        <Route path="/terms" element={<Terms />} />

        {/* 고객용 장난감 관련 */}
        <Route path="/search" element={<SearchToys />} />
        <Route path="/new" element={<NewToys />} />
        <Route path="/toy/:id" element={<ToyDetail />} />

        {/* 열린마당 탭 관련 시작 */}
        {/* 고객용 공지사항 관련 */}
        <Route path="/notice" element={<Notice />} />
        <Route path="/notice/:id" element={<NoticeDetail />} />

        {/* 고객용 자유게시판 관련 */}
        <Route path="/board" element={<Board />} />
        <Route path="/board/write" element={<BoardWrite />} />
        <Route path="/board/:id" element={<BoardDetail />} />
        <Route path="/board/:id/update" element={<BoardUpdate />} />

        {/* 고객용 이용후기 관련 */}
        <Route path="/review" element={<Review />} />
        <Route path="/review/write" element={<ReviewWrite />} />
        <Route path="/review/:id" element={<ReviewDetail />} />
        <Route path="/review/:id/update" element={<ReviewUpdate />} />

        {/* 개인정보처리방침 관련 */}
        <Route path="/privacy" element={<Privacy />} />

        {/* 열린마당 탭 관련 끝 */}

        {/* admin 관련 페이지 시작 */}
        <Route path="/adminPage" element={<AdminHome />} />

        <Route path="/adminPage/notice" element={<AdminNotice />} />
        <Route
          path="/adminPage/notice/:id"
          element={<AdminNoticeDetail />}
        ></Route>
        <Route path="/adminPage/noticeCreate" element={<AdminNoticeCreate />} />

        <Route path="/adminPage/board" element={<AdminBoard />} />
        <Route
          path="/adminPage/board/:id"
          element={<AdminBoardDetail />}
        ></Route>

        <Route path="/adminPage/toy" element={<AdminToy />} />
        <Route path="/adminPage/toy/:id" element={<AdminToyDetail />} />
        <Route path="/adminPage/toyCreate" element={<AdminToyCreate />} />
        <Route path="/adminPage/toyExcel" element={<AdminToyExcelUpload />} />

        <Route path="/adminPage/review" element={<AdminReview />} />
        <Route path="/adminPage/review/:id" element={<AdminReviewDetail />} />

        <Route path="/adminPage/user" element={<AdminUser />} />
        <Route path="/adminPage/user/:id" element={<AdminUserDetail />} />
        {/* admin 관련 페이지 끝 */}

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
