import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Carousel.css";

function prevNav() {
  return `<div class='prev-custom'>‹</div>`;
}
function nextNav() {
  return `<div class='next-custom'>›</div>`;
}

export default function Carousel({ images }) {
  if (images.length === 0) {
    return null;
  }

  return (
    <OwlCarousel
      className="owl-theme"
      items={1}
      margin={0}
      autoplay={true}
      loop={true}
      nav={true}
      navText={[prevNav(), nextNav()]}
    >
      {images.map((image, index) => {
        return (
          <img
            key={`carousel-${index}`}
            src={image}
            alt={`Slide ${index + 1}`}
          />
        );
      })}
    </OwlCarousel>
  );
}
