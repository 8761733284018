import { useEffect } from "react";
import AdminLayout from "../../components/Layout/AdminLayout";

export default function AdminHome() {
  useEffect(() => {
    window.location.replace("/adminPage/toy");
  }, []);

  return <AdminLayout section={"home"}>adminHome</AdminLayout>;
}
