import Layout from "../../components/Layout/Layout";
import tmp_img2 from "../../images/tmp_img2.jpg";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/notice.module.css";
import { backend_origin } from "../../configs/config";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Notice() {
  const [originNoticeList, setOriginNoticeList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const inputSearchRef = useRef(null);

  const navigate = useNavigate();

  const getNoticeList = async () => {
    const response = await fetch(`${backend_origin}/api/notice/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      setNoticeList(json);
      setOriginNoticeList(json);
    }
  };

  useEffect(() => {
    getNoticeList();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title="공지사항" />
      <div className="wrapper">
        <div className={styles.search_wrapper}>
          <input ref={inputSearchRef} placeholder="검색어 입력" />
          <button
            className={styles.schBtn}
            onClick={() => {
              let keyword = inputSearchRef.current.value;
              console.log(keyword);
              const noticeList_tmp = [...originNoticeList];
              const filterNoticeList = noticeList_tmp.filter((notice) =>
                notice.title.includes(keyword)
              );

              setNoticeList(filterNoticeList);
            }}
          >
            검색
          </button>
        </div>
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            {noticeList.map((notice, index) => {
              return (
                <tr
                  key={`notice_${index}`}
                  onClick={() => {
                    navigate(`/notice/${notice.id}`);
                  }}
                >
                  <td>{notice.id}</td>
                  <td>{notice.title}</td>
                  <td>{moment(notice.created_at).format("YYYY-MM-DD")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
