import { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    alert("사이트에서 제공하지 않는 페이지입니다.");
    window.location.replace("/");
  }, []);

  return null;
}
