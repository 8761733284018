import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { backend_origin } from "../../configs/config";
import styles from "./toyDetail.module.css";

import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tmp_img from "../../images/tmp_img.jpg";
import preparingImage from "../../images/preparingImage.png";

export default function ToyDetail() {
  const params = useParams();
  const toyId = params.id;
  const navigate = useNavigate();

  const [toy, setToy] = useState(null);

  const getToyDetail = async () => {
    const response = await fetch(`${backend_origin}/api/toy/${toyId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 장난감입니다.");
      return;
    }
    const json = await response.json();
    console.log(json);
    json.content = json.content.replace(`img `, `img style="max-width:100%;"`);
    setToy(json);
  };

  useEffect(() => {
    getToyDetail();
  }, []);

  return (
    <>
      {toy ? (
        <Layout>
          {/* <img className={styles.mainImage} src={tmp_img} alt="" /> */}
          <div className="wrapper">
            <div className={styles.toy}>
              <div className={styles.toy_img_wrapper}>
                <img
                  className={styles.toyImg}
                  src={
                    toy.image.length !== 0
                      ? `${backend_origin}/${toy.image[0].image}`
                      : preparingImage
                  }
                />
              </div>
              <div className={styles.toy_detail_wrapper}>
                <h2>{toy.name}</h2>
                <table className={styles.toyDetailsTable}>
                  <tbody>
                    <tr>
                      <td><FontAwesomeIcon icon={faCaretRight} /><span>바코드번호</span></td>
                      <td>{toy.code}</td>
                    </tr>
                    <tr>
                      <td><FontAwesomeIcon icon={faCaretRight} /><span>영역</span></td>
                      <td>{toy.subCategory}</td>
                    </tr>
                    <tr>
                      <td><FontAwesomeIcon icon={faCaretRight} /><span>대상연령</span></td>
                      <td>{toy.ageOfUse}</td>
                    </tr>
                    <tr>
                      <td><FontAwesomeIcon icon={faCaretRight} /><span>제조사</span></td>
                      <td>{toy.company}</td>
                    </tr>
                    <tr>
                      <td><FontAwesomeIcon icon={faCaretRight} /><span>대여가능</span></td>
                      <td>{toy.quantity}개</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.toyContent}>
              <div>설명</div>
              <div
                className="view ql-editor"
                dangerouslySetInnerHTML={{ __html: toy.content }}
              ></div>
            </div>
            <div className={styles.toyComponents}>
              <div>구성</div>
              <div>{toy.components}</div>
            </div>
            <div className={styles.toyAddress}>
              <div>주소</div>
              <div>강원특별자치도 정선군 사북읍 사북6길12-6</div>
            </div>
            <div className={styles.toyCallNumber}>
              <div>대여문의</div>
              <div>{"033)592-1310~1311"}</div>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.backToListButton}
                onClick={() => {
                  navigate(`/search`);
                }}
              >
                목록으로
              </button>
            </div>
          </div>
        </Layout>
      ) : null}
    </>
  );
}
