import * as XLSX from "xlsx";
import { useState } from "react";

import AdminLayout from "../../../components/Layout/AdminLayout";
import styles from "./adminToyExcelUpload.module.css";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";

export default function AdminToyExcelUpload() {
  const [toys, setToys] = useState(null);
  const [images, setImages] = useState(null);
  const [progress, setProgress] = useState(null);
  const [totalProgress, setTotalProgress] = useState(null);
  const [unknownToys, setUnknownToys] = useState([]);
  const [noneImgToys, setNoneImgToys] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  // 한 번이라도 업로드 행위를 했는지 - boolean
  const [isuploaded, setIsuploaded] = useState(false);

  /*
  엑셀 파일 헨들링 코드 시작
  */
  const handleExcelFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // 여기서 workbook을 이용하여 엑셀 파일의 데이터를 파싱
      const parsedData = parseExcelData(workbook);
      setToys(parsedData);
    };

    reader.readAsArrayBuffer(file);
  };
  const parseExcelData = (workbook) => {
    // 여기에서 엑셀 데이터 파싱 로직을 구현
    // 예: worksheet를 반복하여 데이터 추출
    const result = [];

    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      rows.splice(0, 1);
      result.push(rows);
    });

    return result[0];
  };
  /*
  엑셀 파일 헨들링 코드 끝
  */

  const handleImagesFiles = (e) => {
    const files = e.target.files;
    setImages([...files]);
  };

  const upload = async () => {
    if (!toys || !images) {
      alert("엑셀과 이미지들을 모두 올려주시기 바랍니다.");
      return;
    }
    setIsUploading(true);

    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        window.location.replace("/login");
        return;
      }
      access = result;
    }

    setProgress(0);
    setTotalProgress(toys.length);
    setNoneImgToys([]);
    const tmpUnknownToys = [];
    const tmpNoneImgToys = [];
    for (const toy of toys) {
      setProgress((prev) => prev + 1);

      // 올바른 row인지 검증
      if (toy.length !== 11) {
        tmpUnknownToys.push(toy);
        continue;
      }
      let i = 0;
      for (i = 0; i < toy.length; i++) {
        if (!toy[i] || toy[i] === "" || toy[i] === null) {
          break;
        }
      }
      if (i !== toy.length) {
        tmpUnknownToys.push(toy);
        continue;
      }

      // toy[0] 은 의미없는 index임.
      const name = toy[1];
      const components = toy[2];
      const company = toy[3];
      const ageOfUse = toy[4];
      const content = toy[5];
      const mainCategory = toy[6];
      const subCategory = toy[7];
      const code = toy[8];
      const imageName = toy[9];
      const quantity = toy[10];
      const isNew = true;
      const image = images.find((item) => item.name === imageName);
      if (!image) {
        tmpNoneImgToys.push(toy);
        continue;
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("components", components);
      formData.append("company", company);
      formData.append("ageOfUse", ageOfUse);
      formData.append("mainCategory", mainCategory);
      formData.append("subCategory", subCategory);
      formData.append("code", code);
      formData.append("content", content);
      formData.append("quantity", quantity);
      formData.append("isNew", isNew);
      formData.append("image", image);
      const response = await fetch(`${backend_origin}/api/toy/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access}`,
        },
        body: formData,
      });
      if (response.status === 200) {
        console.log(`${toy[0]} ${name} 등록완료`);
      }
    }
    setUnknownToys(tmpUnknownToys);
    setNoneImgToys(tmpNoneImgToys);

    setIsUploading(false);
    setIsuploaded(true);
  };

  return (
    <AdminLayout section={"toy"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>장난감 일괄등록</div>
        <div>
          <h3>업로드 안내</h3>
          <p>
            엑셀파일과 이미지를 선택해주세요.
            <br />
            엑셀파일에 기입되어있는 장난감의 이미지명과 동일한 이름의 이미지가
            업로드되어야 합니다.
            <br />
            ※여러 장의 이미지가 선택가능합니다. (Ctrl + A , Shift 활용)
            <br />
            업로드 후 잘못된 형식이나 이미지가 없는 장난감 목록이 나타나며, 오류
            목록에 없는 장난감은 정상적으로 업로드됩니다.
            <br />
          </p>
        </div>
        <table>
          <tbody>
            <tr>
              <td>엑셀파일</td>
              <td>
                <div className={styles.filesWrapper}>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleExcelFile}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>이미지파일</td>
              <td>
                <div className={styles.filesWrapper}>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImagesFiles}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button className={styles.uploadBtn} onClick={upload}>
            장난감 일괄 업로드
          </button>
        </div>
        {progress && totalProgress && isUploading && (
          <div>
            <span>
              업로드 중 ... ({progress} / {totalProgress})
            </span>
          </div>
        )}
        {isuploaded && (
          <div>
            <hr />
            <h3>업로드 결과</h3>
            {/* 형식 오류 */}
            {!isUploading && unknownToys.length !== 0 && (
              <p>
                <h4>형식 오류목록</h4>
                {unknownToys.map((toy, index) => {
                  return (
                    <div key={`unknownToy-${index}`}>
                      <li>
                        {toy[0]}번 "<span>{toy[1]}</span>"에 대한 형식을
                        확인해주시기 바랍니다.
                      </li>
                    </div>
                  );
                })}
              </p>
            )}
            {/* 이미지 오류 */}
            {!isUploading && noneImgToys.length !== 0 && (
              <div>
                <h4>이미지 오류목록</h4>
                {noneImgToys.map((toy, index) => {
                  return (
                    <div key={`noneImgToy-${index}`}>
                      <li>
                        {toy[0]}번 "<span>{toy[1]}</span>"에 대한 이미지(
                        {toy[9]})를 찾을 수 없습니다.
                      </li>
                    </div>
                  );
                })}
              </div>
            )}
            <p>
              그 외 장난감{" "}
              {toys.length - unknownToys.length - noneImgToys.length}개 업로드
              완료.
            </p>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}
