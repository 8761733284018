import moment from "moment";

import { getCookie } from "../../configs/cookie";
import { backend_origin } from "../../configs/config";
import { checkLogined, refreshToken } from "../../functions/account";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPencil, faComment } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout/Layout";
import tmp_img2 from "../../images/tmp_img2.jpg";
import MainBanner from "../../components/MainBanner/MainBanner";

import styles from "../styles/boardDetail.module.css";

export default function BoardDetail() {
  const params = useParams();
  const articleId = params.id;
  const navigate = useNavigate();

  const [article, setArticle] = useState({});
  const [updateClickedComment, setUpdateClickedComment] = useState(null);
  const [inputCreateComment, setInputCreateComment] = useState("");
  const [inputUpdateComment, setInputUpdateComment] = useState("");

  const getArticleDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${articleId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 게시글입니다.");
      navigate("/board");
      return;
    }
    const json = await response.json();
    json.content = json.content.replace(`img `, `img style="max-width:100%;"`);
    setArticle(json);
  };

  const createComment = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/board/${articleId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
      body: JSON.stringify({
        content: inputCreateComment,
      }),
    });
    if (response.status === 200) {
      alert("댓글이 저장되었습니다.");
      window.location.reload();
    } else {
      const json = await response.json();
      console.log(json);
    }
  };

  const updateComment = async (commentId) => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(
      `${backend_origin}/api/board/comment/${commentId}/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: access ? `Bearer ${access}` : null,
        },
        body: JSON.stringify({
          content: inputUpdateComment,
        }),
      }
    );
    if (response.status === 200) {
      alert("댓글이 수정되었습니다.");
      window.location.reload();
    } else {
      const json = await response.json();
      console.log(json);
    }
  };

  const deleteComment = async (commentId) => {
    const confirm = window.confirm("댓글을 삭제하시겠습니까?");
    if (confirm === false) {
      return;
    }
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(
      `${backend_origin}/api/board/comment/${commentId}/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: access ? `Bearer ${access}` : null,
        },
      }
    );
    if (response.status === 204) {
      alert("댓글이 삭제되었습니다.");
      window.location.reload();
    } else {
      const json = await response.json();
      console.log(json);
    }
  };

  useEffect(() => {
    getArticleDetail();
  }, []);

  return (
    <Layout>
      <MainBanner image={tmp_img2} title={"자유게시판"} />
      <div className={styles.wrapper}>
        <div className={styles.content_wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <span className={styles.title}>{article.title}</span>
              <div>
                <span
                  className={styles.backToList}
                  onClick={() => navigate(`/board`)}
                >
                  <FontAwesomeIcon icon={faBars} />
                </span>
                {article.isAuthor ? (
                  <span
                    className={styles.modify}
                    onClick={() => navigate(`/board/${articleId}/update`)}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </span>
                ) : null}
                <span className={styles.date}>
                  {article.created_at !== undefined
                    ? moment(article.created_at).format("YYYY-MM-DD")
                    : null}
                </span>
              </div>
            </div>
            <div
              className="view ql-editor"
              dangerouslySetInnerHTML={{ __html: article.content }}
            ></div>
            <hr />
          </div>

          {/* 댓글 시작 */}

          <div>
            <h4 style={{margin: "15px 0"}}>
              <span className={styles.chat_logo}><FontAwesomeIcon icon={faComment} /></span>
              &nbsp;댓글
            </h4>
            {article.comment
              ? article.comment.map((comment, index) => (
                  <div
                    key={`comment_${index}`}
                    className={`${styles.comment} ${
                      comment.isAuthor ? styles.myComment : ""
                    }`}
                  >
                    <span>
                      {comment.author.name} -{" "}
                      {moment(comment.created_at).format("YYYY-MM-DD")}
                      {comment.created_at !== comment.updated_at
                        ? ` - ${moment(comment.updated_at).format(
                            "YYYY-MM-DD"
                          )}에 수정됨`
                        : null}
                    </span>
                    {comment.isAuthor ? (
                      <span
                        className={styles.modify}
                        onClick={() => {
                          setUpdateClickedComment(index);
                          setInputUpdateComment(comment.content);
                        }}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </span>
                    ) : null}
                    <br />
                    {index === updateClickedComment ? (
                      <>
                        <input
                          value={inputUpdateComment}
                          onChange={(event) => {
                            setInputUpdateComment(event.target.value);
                          }}
                        />
                        <button
                          onClick={() => {
                            updateComment(comment.id);
                          }}
                        >
                          저장
                        </button>
                        <button
                          onClick={() => {
                            deleteComment(comment.id);
                          }}
                        >
                          삭제
                        </button>
                        <button
                          onClick={() => {
                            setUpdateClickedComment(null);
                            setInputUpdateComment("");
                          }}
                        >
                          취소
                        </button>
                      </>
                    ) : (
                      <span>{comment.content}</span>
                    )}
                  </div>
                ))
              : null}
            {/* <h3>댓글 작성</h3> */}
            {checkLogined() ? (
              <>
                <div className={styles.textareaWrapper}>
                  <input
                    className={styles.inputComment}
                    value={inputCreateComment}
                    placeholder="댓글을 남겨주세요."
                    onChange={(event) => {
                      setInputCreateComment(event.target.value);
                    }}
                  />
                  <button className={styles.saveButton} onClick={createComment}>
                    등록
                  </button>
                </div>
              </>
            ) : (
              <span>로그인 후 댓글을 작성할 수 있습니다.</span>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.backToListButton}
              onClick={() => {
                navigate(`/board`);
              }}
            >
              목록으로
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
