import Layout from "../components/Layout/Layout";
import tmp_img2 from "../images/tmp_img2.jpg";
import MainBanner from "../components/MainBanner/MainBanner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles/JstoyIntroduce.module.css";

import inside1 from "../images/inside/1.jpg";
import inside2 from "../images/inside/2.jpg";
import inside3 from "../images/inside/3.jpg";
import inside4 from "../images/inside/4.jpg";

export default function JstoyIntroduce() {
  return (
    <Layout>
      <MainBanner image={tmp_img2} title="장난감도서관 소개" />
      <div className="wrapper">
        <div className={styles.text_wrapper}>
          <div className={styles.sub_title}>
            <span><FontAwesomeIcon icon={faCaretRight} /></span> 
            &nbsp;장난감도서관 정보
          </div>
          <table className={styles.info_table}>
            <tbody>
              <tr>
                <td>개관일</td>
                <td>2023.12.01</td>
              </tr>
              <tr>
                <td>위치</td>
                <td>강원특별자치도 정선군 사북읍 사북6길 12-6</td>
              </tr>
              <tr>
                <td>규모</td>
                <td>300㎡</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.img_wrapper}>
          <div>
            <span>내부사진 1</span>
            <img src={inside1} alt="내부사진 1" />
          </div>
          <div>
            <span>내부사진 2</span>
            <img src={inside2} alt="내부사진 2" />
          </div>
          <div>
            <span>내부사진 3</span>
            <img src={inside3} alt="내부사진 3" />
          </div>
          <div>
            <span>내부사진 4</span>
            <img src={inside4} alt="내부사진 4" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
