// local
// export const backend_origin = `http://127.0.0.1:8000`;
// export const frontend_origin = `http://127.0.0.1:3000`;

// prod
export const backend_origin = `https://aritoy.co.kr`;
export const frontend_origin = `https://aritoy.co.kr`;

//파일 최대 용량
export const fileMaxSize = 1 * 1024 * 1024;

// 카카오 로그인 세팅 TODO : 의뢰주의 것으로 변경했는가?
const KAKAO_REST_API_KEY = `82b60171981ce25473c14b11d7c922e8`;
const KAKAO_REDIRECT_URI = `${backend_origin}/accounts/kakao/login/callback/`;
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;

// 구글 로그인 세팅 TODO : 의뢰주의 것으로 변경했는가?
const GOOGLE_CLIENT_ID = `447765430818-530eu2455f8e7pf241njau2s16ndicgh.apps.googleusercontent.com`;
const GOOGLE_CALLBACK_URI = `${backend_origin}/accounts/google/login/callback/`;
const GOOGLE_PROFILE_SCOPE = `https://www.googleapis.com/auth/userinfo.profile`;
const GOOGLE_PHONENUMBER_SCOPE = `https://www.googleapis.com/auth/user.phonenumbers.read`;
const GOOGLE_EMAIL_SCOPE = `https://www.googleapis.com/auth/userinfo.email`;
export const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${GOOGLE_CALLBACK_URI}&scope=${GOOGLE_PROFILE_SCOPE} ${GOOGLE_PHONENUMBER_SCOPE} ${GOOGLE_EMAIL_SCOPE}`;

// 네이버 로그인 세팅 TODO : 의뢰주의 것으로 변경했는가?
const NAVER_CLIENT_ID = `BGhdLRrNLDw4z5BSaBw8`;
const NAVER_SECRET = `VNbfHUpBby`;
const NAVER_STATE = `2efji92fer0u2f3reji8`;
const NAVER_CALLBACK_URI = `${backend_origin}/accounts/naver/login/callback/`;
export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&redirect_uri=${NAVER_CALLBACK_URI}&state=${NAVER_STATE}`;
