import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";
import { useEffect, useRef, useState } from "react";
import styles from "./adminUserDetail.module.css";
import moment from "moment";

export default function AdminUserDetail() {
  const params = useParams();
  const userId = params.id;
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const getUserDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/user/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않습니다.");
      navigate("/adminPage/user");
      return;
    }
    const user = await response.json();
    console.log(user);
    setUser(user);
  };

  const disableUser = async () => {
    if (
      !window.confirm(
        "비활성화 후 다시 되돌릴 수 없습니다.\n정말 계정을 비활성화 하시겠습니까?"
      )
    )
      return;
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(
      `${backend_origin}/api/user/${userId}/disable/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: access ? `Bearer ${access}` : null,
        },
      }
    );
    if (response.status === 200) {
      alert("비활성화 되었습니다.");
      return;
    } else if (response.status === 404) {
      alert("존재하지 않습니다.");
      return;
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <AdminLayout section={"user"}>
      {user && (
        <div className={styles.contents_wrapper}>
          <div className={styles.header}>계정 상세</div>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>이름</td>
                <td>{user.name}</td>
              </tr>
              <tr>
                <td>아이디</td>
                <td>{user.username}</td>
              </tr>
              <tr>
                <td>이메일</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>권한</td>
                <td>{user.isManager ? "관리자" : "일반"}</td>
              </tr>
              <tr>
                <td>활성여부</td>
                <td>
                  <div className={styles.activate}>
                    <div>{user.is_active ? "활성" : "비활성"}</div>
                    {user.is_active 
                      ? <button onClick={disableUser}>비활성화</button>
                      : ""
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>가입 날짜</td>
                <td>{moment(user.date_joined).format("YYYY.MM.DD HH:mm")}</td>
              </tr>
              <tr>
                <td>최근 로그인</td>
                <td>
                  {user.last_login
                    ? moment(user.last_login).format("YYYY.MM.DD HH:mm")
                    : `-----`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </AdminLayout>
  );
}
