import { backend_origin } from "../../../configs/config";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import AdminLayout from "../../../components/Layout/AdminLayout";
import styles from "./adminBoard.module.css";

export default function AdminBoard() {
  const [originBoardList, setOriginBoardList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const inputSearchRef = useRef(null);

  const navigate = useNavigate();

  const getBoardList = async () => {
    const response = await fetch(`${backend_origin}/api/board/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log(json);
    setBoardList(json);
    setOriginBoardList(json);
  };

  useEffect(() => {
    getBoardList();

    return () => {};
  }, []);

  return (
    <AdminLayout section={"board"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>자유게시판 목록</div>
        <div className={styles.header_wrapper}>
          <div className={styles.search_wrapper}>
            <input ref={inputSearchRef} placeholder="검색어 입력" />
            <button
              className={styles.schBtn}
              onClick={() => {
                let keyword = inputSearchRef.current.value;
                const boardList_tmp = [...originBoardList];
                const filterboardList = boardList_tmp.filter((board) =>
                  board.title.includes(keyword)
                );

                setBoardList(filterboardList);
              }}
            >
              검색
            </button>
          </div>
        </div>
        <table className={styles.board_list}>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>작성자</th>
              <th>만든 날짜</th>
            </tr>
          </thead>
          <tbody>
            {boardList.map((board, idx) => {
              return (
                <tr
                  key={idx}
                  className="Raw"
                  onClick={() => {
                    navigate(`/adminPage/board/${board.id}`);
                  }}
                >
                  <td className={styles.id}>{board.id}</td>
                  <td className={styles.name}>{board.title}</td>
                  <td>{board.author.name}</td>
                  <td className={styles.date}>
                    {moment(board.created_at).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
