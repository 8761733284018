import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";
import { useEffect, useRef, useState } from "react";
import Veditor from "../../../components/Editor/Veditor";
import styles from "./adminNoticeDetail.module.css";

export default function AdminNoticeDetail() {
  const params = useParams();
  const noticeId = params.id;
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const getNoticeDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/notice/${noticeId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않습니다.");
      navigate("/adminPage/notice");
      return;
    }
    const notice = await response.json();
    notice.content = notice.content.replace(
      `img `,
      `img style="max-width:100%;"`
    );
    setTitle(notice.title);
    setContent(notice.content);
  };

  const updateNotice = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const response = await fetch(`${backend_origin}/api/notice/${noticeId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        title: title,
        content: content,
      }),
    });
    if (response.status === 200) {
      alert("저장되었습니다.");
      navigate(`/adminPage/notice`);
    } else if (response.status === 400) {
      const json = await response.json();
      console.log(json);
      alert("모두 입력해주세요.");
    }
  };

  const deleteNotice = async () => {
    if (!window.confirm("정말 삭제합니까?")) {
      return;
    }
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh === undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/notice/${noticeId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 204) {
      alert("삭제되었습니다.");
      navigate(`/adminPage/notice`);
      return;
    }
  };

  useEffect(() => {
    getNoticeDetail();
  }, []);

  return (
    <AdminLayout section={"notice"}>
      <div className={styles.contents_wrapper}>
        <div className={styles.header}>공지사항 수정</div>
        <div className={styles.editorDiv}>
          <div>
            <input
              className={styles.titleInput}
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              placeholder="제목을 입력해주세요."
            />
          </div>
          <div style={{ paddingBottom: 80 }}>
            <Veditor
              content={content}
              setContent={setContent}
              placeholder={"내용을 작성해주세요."}
              to="notice"
            />
          </div>
        </div>
        <div className={styles.btn_wrapper}>
          <button className={styles.saveBtn} onClick={updateNotice}>
            저장
          </button>
          <button className={styles.delBtn} onClick={deleteNotice}>
            삭제
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
