import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { getCookie } from "../../../configs/cookie";
import { refreshToken } from "../../../functions/account";
import { backend_origin } from "../../../configs/config";
import { useEffect, useRef, useState } from "react";
import Veditor from "../../../components/Editor/Veditor";
import styles from "./adminToyDetail.module.css";

export default function AdminToyDetail() {
  const params = useParams();
  const toyId = params.id;
  const navigate = useNavigate();

  const [toy, setToy] = useState(null);
  const [content, setContent] = useState("");
  const [imgFileURL, setImgFileURL] = useState(null);
  const imgRef = useRef();

  // 이미지 업로드 input의 onChange
  const saveImgFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFileURL(reader.result);
    };
  };

  const getToyDetail = async () => {
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/toy/${toyId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access ? `Bearer ${access}` : null,
      },
    });
    if (response.status === 404) {
      alert("존재하지 않는 장난감입니다.");
      navigate("/adminpage/toy");
      return;
    }
    const toy = await response.json();
    toy.content = toy.content.replace(`img `, `img style="max-width:100%;"`);
    if (toy.image.length !== 0)
      setImgFileURL(`${backend_origin}/${toy.image[0].image}`);
    setContent(toy.content);
    setToy(toy);
  };

  const updateToy = async () => {
    let access = getCookie("access");
    while (access === undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result === false) {
        alert("로그인 후 이용해주세요.");
        navigate("/login");
        return;
      }
      access = result;
    }
    const formData = new FormData();
    console.log(toy);
    formData.append("name", toy.name);
    formData.append("components", toy.components);
    formData.append("company", toy.company);
    formData.append("ageOfUse", toy.ageOfUse);
    formData.append("mainCategory", toy.mainCategory);
    formData.append("subCategory", toy.subCategory);
    formData.append("code", toy.code);
    formData.append("content", content);
    formData.append("quantity", toy.quantity);
    formData.append("isNew", toy.isNew);
    formData.append("image", imgRef.current.files[0]);
    const response = await fetch(`${backend_origin}/api/toy/${toyId}/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      alert("저장되었습니다.");
      navigate(`/adminpage/toy`);
    } else if (response.status === 400) {
      const json = await response.json();
      console.log(json);
      alert("모두 입력해주세요.");
    }
  };

  const deleteToy = async () => {
    if (!window.confirm("정말 삭제합니까?")) {
      return;
    }
    let access = getCookie("access");
    const refresh = getCookie("refresh");
    if (access === undefined && refresh === undefined) {
      return;
    }
    if (access === undefined && refresh !== undefined) {
      // 토큰이 만료되어 갱신시도
      const result = await refreshToken();
      if (result !== false) {
        access = result;
      }
    }
    const response = await fetch(`${backend_origin}/api/toy/${toyId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 204) {
      alert("삭제되었습니다.");
      navigate(`/adminpage/toy`);
      return;
    }
  };

  const changeToyState = (key, value) => {
    const tmpDict = { ...toy };
    tmpDict[key] = value;
    setToy(tmpDict);
  };

  useEffect(() => {
    getToyDetail();
  }, []);

  return (
    <AdminLayout section={"toy"}>
      {toy && (
        <div className={styles.contents_wrapper}>
          <div className={styles.header}>장난감 수정</div>
          <table className={styles.table}>
            <tr>
              <td>이름</td>
              <td>
                <input
                  value={toy.name}
                  onChange={(e) => changeToyState("name", e.target.value)}
                  placeholder="이름을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>구성품</td>
              <td>
                <input
                  value={toy.components}
                  onChange={(e) => changeToyState("components", e.target.value)}
                  placeholder="구성품을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>제조사</td>
              <td>
                <input
                  value={toy.company}
                  onChange={(e) => changeToyState("company", e.target.value)}
                  placeholder="제조사를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>사용연령</td>
              <td>
                <input
                  value={toy.ageOfUse}
                  onChange={(e) => changeToyState("ageOfUse", e.target.value)}
                  placeholder="사용연령을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>대분류</td>
              <td>
                <input
                  value={toy.mainCategory}
                  onChange={(e) =>
                    changeToyState("mainCategory", e.target.value)
                  }
                  placeholder="대분류를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>소분류</td>
              <td>
                <input
                  value={toy.subCategory}
                  onChange={(e) =>
                    changeToyState("subCategory", e.target.value)
                  }
                  placeholder="소분류를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>바코드</td>
              <td>
                <input
                  value={toy.code}
                  onChange={(e) => changeToyState("code", e.target.value)}
                  placeholder="바코드를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>대표 이미지</td>
              <td>
                {imgFileURL ? (
                  <>
                    <img
                      className={styles.toyThumbnailImage}
                      src={imgFileURL}
                      alt="장난감 대표 이미지"
                    />
                    <br />
                  </>
                ) : null}
                <label
                  className={styles.toyThumbnailImageLabel}
                  htmlFor="toyThumnailImg"
                >
                  장난감 대표 이미지 설정
                </label>
                <input
                  id="toyThumnailImg"
                  style={{ display: "none" }}
                  ref={imgRef}
                  type="file"
                  accept="image/*"
                  onChange={saveImgFile}
                />
              </td>
            </tr>
            <tr>
              <td>대여가능 수량</td>
              <td>
                <input
                  value={toy.quantity}
                  onChange={(e) =>
                    changeToyState("quantity", Number(e.target.value))
                  }
                  type="number"
                  min="1"
                  defaultValue="1"
                />
              </td>
            </tr>
            <tr>
              <td>신규여부</td>
              <td>
                <input
                  checked={toy.isNew}
                  onChange={(e) => changeToyState("isNew", !toy.isNew)}
                  type="checkbox"
                />
              </td>
            </tr>
          </table>
          <div className={styles.editorDiv}>
            <div style={{ paddingBottom: 80 }}>
              <Veditor
                content={content}
                setContent={setContent}
                placeholder={"내용을 작성해주세요."}
                to="toy_content"
              />
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <button className={styles.saveBtn} onClick={updateToy}>
              저장
            </button>
            <button className={styles.delBtn} onClick={deleteToy}>
              삭제
            </button>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}
