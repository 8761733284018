import Layout from "../components/Layout/Layout";
import tmp_img2 from "../images/tmp_img2.jpg";
import MainBanner from "../components/MainBanner/MainBanner";
import styles from "./styles/introduce.module.css";

import introduce_img from "../images/introduce.jpg";
import introduce_img2 from "../images/introduce2.jpg"

export default function Introduce() {
  return (
    <Layout>
      <MainBanner image={tmp_img2} title="인사말" />
      <div className="wrapper">
        {/* <h1>인사말</h1> */}
        <div className={styles.introduce_wrapper}>
          {/* <img src={introduce_img} alt="인사말" /> */}
          <div className={styles.content_wrapper}>
            <span><b>아리 장난감 도서관</b>에 방문해주셔서 감사합니다.</span>
            <div className={styles.writing_wrapper}>
              <div className={styles.writing_img}>
                <img src={introduce_img2} alt="소개이미지" />
              </div>
              <div className={styles.writing}>
                <p>
                  안녕하세요!
                </p>
                <p>
                  아리 장난감 도서관에 찾아주셔서 감사합니다.<br />
                  저희는 어린이들의 건강한 성장과 행복한 놀이를 위해 노력하고 있습니다.<br />
                  우리 장난감 도서관는 다양한 종류의 안전하고 교육적인 장난감들로 가득 차 있어,<br />
                  아이들이 더욱 창의적으로 놀 수 있도록 도와드립니다.<br />
                </p>
                <p>
                  아이들은 놀이를 통해 세상을 탐험하고 배우며, 우리는 그 과정에서 도움이 될 수 있는 장난감들을 제공하고자 합니다.<br />
                  또한, 부모님들에게는 안심하고 아이들을 맡길 수 있는 환경을 제공하여 편안한 시간을 보내실 수 있도록 노력하고 있습니다.<br />
                </p>
                <p>
                  우리 장난감 도서관은 언제나 여러분의 소중한 아이들을 환영합니다.<br /> 
                  궁금한 점이나 요청사항이 있으시면 언제든지 문의해 주세요.<br />
                  아이들이 웃으며 놀며 즐거운 시간을 보낼 수 있도록 최선을 다하겠습니다.<br />
                </p>
                <p>
                  함께 행복한 순간들을 만들어가기 위해 노력하는 아리 장난감 도서관이 되겠습니다.<br />
                </p>
                <p>
                  감사합니다!<br />     
                </p>
                <p>
                  <span style={{color:"#333", fontSize:"18px"}}>아리 장난감 도서관 대표</span>&nbsp;&nbsp;황&nbsp;&nbsp;진&nbsp;&nbsp;호
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
