import { useState } from "react";
import Layout from "../components/Layout/Layout";

import {
  GOOGLE_AUTH_URL,
  KAKAO_AUTH_URL,
  NAVER_AUTH_URL,
} from "../configs/config";

import KAKAO_LOGIN_IMG from "../images/login/kakao.png";
import GOOGLE_LOGIN_IMG from "../images/login/google.png";
import NAVER_LOGIN_IMG from "../images/login/naver.png";
import { login } from "../functions/account";

import styles from "./styles/login.module.css";

function Login() {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);

  return (
    <Layout>
      <div className="wrapper">
        <form
          className={styles.loginForm}
          onSubmit={(event) => {
            login(event, isRemember, id, password);
          }}
        >
          <header>LOGIN</header>
          <div>
            <input
              className="loginInput"
              type="text"
              id="id"
              value={id}
              placeholder="아이디"
              onChange={(e) => setId(e.target.value)}
            />
          </div>
          <div>
            <input
              className="loginInput"
              type="password"
              value={password}
              placeholder="비밀번호"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label>자동로그인</label>
            <input
              type="checkbox"
              value={isRemember}
              onChange={(e) => setIsRemember(e.target.value)}
            />
          </div>
          <button type="submit">로그인</button>
          <div className={styles.socialLoginWrapper}>
            <div>
              <a href={KAKAO_AUTH_URL}>
                <img src={KAKAO_LOGIN_IMG} />
              </a>
            </div>
            <div>
              <a href={GOOGLE_AUTH_URL}>
                <img src={GOOGLE_LOGIN_IMG} />
              </a>
            </div>
            <div>
              <a href={NAVER_AUTH_URL}>
                <img src={NAVER_LOGIN_IMG} />
              </a>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default Login;
